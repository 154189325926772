import { IAppleDeviceGroup } from '../AppleDeviceGroups';

export interface IAppleApp {
  id: string;
  trackName?: string;
  bundleId: string;
  sellerName?: string;
  iconUrl?: string;
  checked?: boolean;
  configuration?: Record<string, string | number>;
  configurationFileContent?: string;
}

export interface IAppleAppGroup {
  id?: string;
  name?: string;
  os?: string;
  description?: string;
  apps: IAppleApp[];
  deviceGroups?: IAppleDeviceGroup[];
}

export const initAppleAppGroup: IAppleAppGroup = {
  name: '',
  description: '',
  os: 'ios',
  apps: [],
  deviceGroups: [],
};
