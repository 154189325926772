import { SelectChangeEvent } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { ProfileContext } from '../../..';
import CustomSelectInput from '../../../../../components/CustomSelectInput';

function PerAppVPN() {
  const { profile, setProfile } = useContext(ProfileContext);
  const [selectedVPN, setSelectedVPN] = useState<string>(
    profile.applayerVPN?.jsonPayload?.type ?? 'NONONENE',
  );

  const menuItems = [
    {
      view: 'None',
      value: 'NONE',
    },
    {
      view: 'miniOrange',
      value: 'MO-VPN',
    },
  ];

  useEffect(() => {
    handleAppLayerVPN();
  }, [selectedVPN]);

  const handleAppLayerVPN = () => {
    let applayerVPNJSON = {};
    if (selectedVPN === 'MO-VPN') {
      applayerVPNJSON = {
        jsonPayload: {
          type: 'MO-VPN',
        },
      };
    }

    setProfile((prev) => {
      console.log({
        ...prev,
        applayerVPN: applayerVPNJSON,
      });

      return {
        ...prev,
        applayerVPN: JSON.parse(JSON.stringify(applayerVPNJSON)),
      };
    });
  };

  return (
    <>
      <CustomSelectInput
        name='Per-App VPN'
        menuItems={menuItems}
        value={selectedVPN}
        onChange={(e: SelectChangeEvent) => setSelectedVPN(e.target.value)}
      />
      {selectedVPN === 'MO-VPN' && null}
    </>
  );
}

export default PerAppVPN;
