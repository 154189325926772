/* eslint-disable react/jsx-props-no-spreading */
import {
  Box,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Tooltip,
} from '@mui/material';

/* eslint-disable  @typescript-eslint/no-explicit-any */
function CustomMultiSelectInput(props: any) {
  const { name, label, menuItems, value, handleChange, ...restProps } = props;

  return (
    <FormControl fullWidth size='small'>
      <InputLabel id={name}>{label || 'Select'}</InputLabel>
      <Select
        labelId={name}
        size='small'
        label={label || 'Select'}
        multiple
        name={name}
        value={value}
        onChange={handleChange}
        renderValue={(selected: string[]) =>
          menuItems
            .filter((item: any) => selected.includes(item.value))
            .map((item: any) => item.view)
            .join(', ')
        }
        {...restProps}
      >
        {menuItems.map((item: any) => (
          <MenuItem key={item.view} value={item.value}>
            <Tooltip
              title={item.tooltip}
              placement='right-start'
              disableHoverListener={!item.tooltip}
            >
              <Box display='flex' gap={1} alignItems='center' width='100%'>
                <Checkbox checked={value.includes(item.value)} />
                <ListItemText primary={item.view} />
              </Box>
            </Tooltip>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default CustomMultiSelectInput;
