import AddIcon from '@mui/icons-material/Add';
import { Button, Grid } from '@mui/material';
import { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import appleLogo from '../../assets/images/apple-logo.png';
import CustomHeader from '../../components/CustomHeader';
import ErrorSuccessSnackbar from '../../components/ErrorSuccessSnackbar';
import { APPLE_PROFILE_CONST, EMPTY_STRING } from '../../constants';
import MainLayout from '../../layouts/MainLayout';
import { getAppleEnrollmentDetails } from '../../redux/slices/appleEnrollment/appleEnrollmentSlice';
import { getAppleProfiles, updateMessage } from '../../redux/slices/appleProfile/appleProfileSlice';
import { AppDispatch, RootState } from '../../redux/store';
import { messageInit } from '../../utils/common-constants';
import ProfileCard from './components/ProfileCard';
import ProfileDialog from './components/ProfileDialog';
import { AppleOSType, IAppleProfile, initAppleProfile } from './definitions';

export const ProfileContext = createContext<{
  osType: AppleOSType;
  profile: IAppleProfile;
  setProfile: React.Dispatch<React.SetStateAction<IAppleProfile>>;
}>({ osType: 'ios', profile: initAppleProfile, setProfile: () => {} });

function AppleProfiles() {
  const dispatch = useDispatch<AppDispatch>();
  const appleEnrollment = useSelector((state: RootState) => state.appleEnrollment);
  const profiles = useSelector((state: RootState) => state.appleProfile);

  const [isCreate, setIsCreate] = useState<boolean>(true);
  const [profile, setProfile] = useState<IAppleProfile>(initAppleProfile);
  const [osType] = useState<AppleOSType>('ios');
  const [openprofileDialog, setOpenProfileDialog] = useState<boolean>(false);

  const profileContextProviderValue = useMemo(
    () => ({
      osType,
      profile,
      setProfile,
    }),
    [profile],
  );

  const handleProfileDialog = useCallback((create: boolean, profileReceived: IAppleProfile) => {
    if (create) {
      setIsCreate(true);
    } else {
      setIsCreate(false);
    }
    setProfile(profileReceived);
    setOpenProfileDialog((prev) => !prev);
  }, []);

  useEffect(() => {
    dispatch(getAppleProfiles());
    if (
      appleEnrollment.appleEnrollment.appleId === EMPTY_STRING ||
      appleEnrollment.appleEnrollment.apnsTopic === EMPTY_STRING
    ) {
      dispatch(getAppleEnrollmentDetails());
    }
  }, []);

  return (
    <MainLayout>
      <CustomHeader logo={appleLogo} title={APPLE_PROFILE_CONST.PAGE_HEADER} />
      <Grid
        container
        direction='row'
        justifyContent='flex-start'
        alignItems='center'
        padding={2}
        spacing={2}
      >
        <Grid item>
          <Button variant='contained' onClick={() => handleProfileDialog(true, profile)}>
            <AddIcon /> Create Profile
          </Button>
        </Grid>
      </Grid>
      <Grid container direction='row' justifyContent='flex-start' alignItems='center' padding={2}>
        {profiles.appleProfiles.length > 0 &&
          profiles.appleProfiles.map((item: IAppleProfile) => (
            <Grid item key={item.name} xs={12} sm={3} sx={{ pb: 2 }}>
              <ProfileCard
                profile={item}
                handleProfileDialog={handleProfileDialog}
                noEdit={item.name === APPLE_PROFILE_CONST.MO_VPN_NAME}
              />
            </Grid>
          ))}
      </Grid>

      <ProfileContext.Provider value={profileContextProviderValue}>
        <ProfileDialog
          isCreate={isCreate}
          openProfileDialog={openprofileDialog}
          handleProfileDialog={handleProfileDialog}
        />
      </ProfileContext.Provider>
      {(profiles.message.error || profiles.message.success) && (
        <ErrorSuccessSnackbar
          opened={profiles.message.error || profiles.message.success}
          onClose={() => dispatch(updateMessage(messageInit))}
          errorMessage={profiles.message.errorMessage}
          successMessage={profiles.message.successMessage}
        />
      )}
    </MainLayout>
  );
}

export default AppleProfiles;
