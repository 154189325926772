import { IAppleApp } from '../../pages/AppleAppGroups/definitions';

function prepareAppsDataForAPI(apps: IAppleApp[]) {
  return apps.map(({ id, bundleId, checked, configurationFileContent }) => ({
    id,
    bundleId,
    checked,
    configurationFileContent,
  }));
}

export default prepareAppsDataForAPI;
