import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { COLOR_RED } from '../../theme';

interface IProps {
  open: boolean;
  onClose: () => void;
  onDelete: () => void;
  content: React.ReactNode;
}

function EnterpriseDeleteDialogBox(props: IProps) {
  const { open, onClose, onDelete, content } = props;

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        Confirm Deletion
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>{content}</DialogContent>
      <DialogActions>
        <Button
          onClick={onDelete}
          variant='outlined'
          style={{ borderColor: COLOR_RED, color: COLOR_RED }}
        >
          Delete
        </Button>
        <Button onClick={onClose} variant='contained'>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default EnterpriseDeleteDialogBox;
