import { Box, Button, FormLabel, TextField, Typography } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import MiniOrangelogo from '../../assets/images/miniorange-logo.webp';
import ErrorMessage from '../../components/ErrorMessage';
import { DEVICE_RESTRICTION_VALIDATE_CHALLENGE } from '../../utils/routes-defs';

const axiosInstance = () => {
  const Axios = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_BASE_URL,
  });

  return Axios;
};

interface IError {
  isError: boolean;
  message: string;
}

function DeviceVerification() {
  const location = useLocation();
  const navigate = useNavigate(); // Initialize useNavigate
  const [transactionId, setTransactionId] = useState<string | null>(null);
  const [otpInputField, setOtpInputField] = useState<string>('');
  const [error, setError] = useState<IError>({
    isError: false,
    message: '',
  });

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setOtpInputField(value);
  };

  const validateMFA = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (!otpInputField) {
      setError({
        isError: true,
        message: 'Please enter OTP',
      });
      return;
    }

    const payload = {
      transactionId,
      token: otpInputField,
    };

    axiosInstance()
      .post(DEVICE_RESTRICTION_VALIDATE_CHALLENGE, payload)
      .then(() => {
        setError({
          isError: false,
          message: '',
        });
        // Navigate
        navigate('/device-verification-success');
      })
      .catch((err) => {
        setError({
          isError: true,
          message: err.response.data.errors[0].message,
        });
      });
  };

  useEffect(() => {
    // Extract query parameters
    const queryParams = new URLSearchParams(location.search);
    const transactionParam = queryParams.get('transaction');

    if (transactionParam) {
      setTransactionId(transactionParam);
    }
  }, [location.search]);

  return (
    <Box
      component='span'
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        fontFamily: 'Poppins !important',
        overflow: 'hidden',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          padding: '1rem',
        }}
      >
        <img src={MiniOrangelogo} height='60' alt='miniorange-logo' />
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          pt: '4rem',
        }}
      >
        <Box component='form' sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
          <Typography variant='h5' align='center' color='#717171'>
            Validate your enrolled device
          </Typography>
          {error.isError && <ErrorMessage message={error.message} />}
          <Box>
            <FormLabel>Please enter the OTP you received to validate your device</FormLabel>
            <TextField
              required
              fullWidth
              size='small'
              id='otp'
              name='otp'
              onChange={onChange}
              placeholder='Enter OTP here'
            />
          </Box>

          <Button
            onClick={(e) => validateMFA(e)}
            fullWidth
            variant='contained'
            sx={{
              backgroundColor: '#2D3748',
              textTransform: 'none',
              '&:hover': { background: '#2D3748' },
            }}
          >
            Verify
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default DeviceVerification;
