import LaptopMacIcon from '@mui/icons-material/LaptopMac';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import {
  Card,
  CardContent,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { APPLE_OS_TYPES } from '../../../../constants';
import { IAppleProfile } from '../../definitions';
import DeleteProfileDialog from '../DeleteProfileDialog';

interface IProps {
  profile: IAppleProfile;
  handleProfileDialog: (create: boolean, profile: IAppleProfile) => void;
  noEdit?: boolean;
}

ProfileCard.defaultProps = {
  noEdit: false,
};

function ProfileCard(props: IProps) {
  const { profile, handleProfileDialog, noEdit } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = () => {
    handleProfileDialog(false, profile);
    handleMenuClose();
  };

  const handleDelete = () => {
    setDeleteDialogOpen(true);
    handleMenuClose();
  };

  return (
    <>
      <Card sx={{ maxWidth: 275 }} elevation={2}>
        <CardContent>
          <Stack direction='row' justifyContent='space-between'>
            <Typography variant='h6' color='text.primary' gutterBottom>
              {profile.os === APPLE_OS_TYPES.ios ? (
                <Tooltip title={APPLE_OS_TYPES.ios}>
                  <PhoneIphoneIcon sx={{ verticalAlign: 'middle', marginRight: '2px' }} />
                </Tooltip>
              ) : null}
              {profile.os === APPLE_OS_TYPES.macos ? (
                <Tooltip title={APPLE_OS_TYPES.macos}>
                  <LaptopMacIcon sx={{ verticalAlign: 'middle', marginRight: '2px' }} />
                </Tooltip>
              ) : null}
              {profile.name}
            </Typography>
            {noEdit ? null : (
              <>
                <IconButton onClick={handleMenuClick}>
                  <MoreVertIcon />
                </IconButton>
                <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
                  <MenuItem onClick={handleEdit}>Edit</MenuItem>
                  <MenuItem onClick={handleDelete}>Delete</MenuItem>
                </Menu>
              </>
            )}
          </Stack>
          <Stack
            direction='column'
            justifyContent='space-between'
            sx={{ height: '30px', overflow: 'hidden' }}
          >
            <Typography color='text.primary' sx={{ fontSize: 16 }}>
              {profile.description}
            </Typography>
          </Stack>
        </CardContent>
      </Card>
      <DeleteProfileDialog
        profile={profile}
        openDeleteDialog={deleteDialogOpen}
        handleDeleteDialog={setDeleteDialogOpen}
      />
    </>
  );
}

export default React.memo(ProfileCard);
