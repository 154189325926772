import CloseIcon from '@mui/icons-material/Close';
import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PolicyContext } from '../../../../..';
import { updateMessage } from '../../../../../../../redux/slices/androidPolicy/androidPolicySlice';
import { AppDispatch, RootState } from '../../../../../../../redux/store';
import { messageInit } from '../../../../../../../utils/common-constants';
import { IApplicationMeta } from '../../../../../definitions';
import { getApp } from '../../../../../../../apis';

// eslint-disable-next-line
declare const gapi: any;

interface IProps {
  open: boolean;
  onClose: () => void;
}

function SelectAppsComponent(props: IProps) {
  const { open, onClose } = props;
  const { setPolicy } = useContext(PolicyContext);
  const webtoken = useSelector((state: RootState) => state.androidPolicy.webtoken);
  const dispatch = useDispatch<AppDispatch>();
  const enterpriseId = useSelector(
    (state: RootState) => state.androidEnterprise.enterprise.enterpriseId,
  );

  const loadGoogleAPI = () => {
    gapi.load('gapi.iframes', () => {
      const options = {
        url: `https://play.google.com/work/embedded/search?token=${webtoken}&mode=SELECT`,
        where: document.getElementById('google-iframe-container'),
        attributes: { style: 'width: 100%; height: 100%', scrolling: 'yes' },
      };
      const iframe = gapi.iframes.getContext().openChild(options);
      iframe.register(
        'onproductselect',
        /* eslint-disable  @typescript-eslint/no-explicit-any */
        async (event: any) => {
          try {
            const response = await getApp(event.packageName, enterpriseId);
            const { iconUrl, title, managedConfiguration, permissions } = response.data.application;

            setPolicy((prev) => {
              const prevApplicationsState = prev.appCatalog?.applications;

              const appAlreadySelected = prevApplicationsState.some(
                (app) => app.appname === event.packageName,
              );

              if (appAlreadySelected) {
                return prev;
              }
              return {
                ...prev,
                appCatalog: {
                  ...prev.appCatalog,
                  applications: [
                    ...prevApplicationsState,
                    {
                      appname: event.packageName,
                      appInstallationType: 'force_install',
                      appDisabled: false,
                      appMiniVersion: 0,
                      appPolicy: 'prompt',
                      appGrants: [],
                      appScopes: [],
                      appManagedConfiguration: {
                        appConfigTemplate: '',
                      },
                      appConnectedWorkAndPersonal: 'disallowed',
                      appUpdateMode: 'default',
                      appOnVpnLockdownExemption: 'enforced',
                      appWidgets: 'disallowed',
                      appMeta: {
                        title,
                        managedConfiguration,
                        iconUrl,
                        permissions: permissions as IApplicationMeta['permissions'],
                      },
                    },
                  ],
                },
              };
            });
            dispatch(
              updateMessage({
                ...messageInit,
                success: true,
                successMessage: `${event.packageName} selected`,
              }),
            );
          } catch (error) {
            dispatch(
              updateMessage({
                ...messageInit,
                error: true,
                errorMessage: 'Error selecting the app',
              }),
            );
          }
        },
        gapi.iframes.CROSS_ORIGIN_IFRAMES_FILTER,
      );
    });
  };
  useEffect(() => {
    if (open) {
      setTimeout(() => {
        loadGoogleAPI();
      }, 500);
    }
  }, [open]);
  return (
    <Dialog fullWidth maxWidth='lg' onClose={onClose} open={open}>
      <DialogTitle>Add Applications</DialogTitle>
      <IconButton
        aria-label='close'
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <div id='google-iframe-container' style={{ height: '500px' }} />
      </DialogContent>
    </Dialog>
  );
}

export default SelectAppsComponent;
