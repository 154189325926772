import { IconButton, Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

interface IProps {
  infoText: string;
}

function InfoTooltip(props: IProps) {
  const { infoText } = props;
  return (
    <Tooltip title={infoText} placement='right'>
      <IconButton size='small' sx={{ width: 17, height: 17 }}>
        <InfoIcon fontSize='small' />
      </IconButton>
    </Tooltip>
  );
}

export default InfoTooltip;
