/* eslint-disable react/jsx-props-no-spreading */
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { Button } from '@mui/material';

interface IProps {
  text: string;
  handleFileDownload: () => void;
  // All other button props
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  [x: string]: any;
}
function FileDownloadButton(props: IProps) {
  const { text, handleFileDownload, ...restProps } = props;

  return (
    <Button
      component='label'
      size='small'
      variant='contained'
      startIcon={<FileDownloadOutlinedIcon />}
      onClick={handleFileDownload}
      {...restProps}
    >
      {text}
    </Button>
  );
}

export default FileDownloadButton;
