import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Link,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import MiniOrangelogo from '../../assets/images/miniorange-logo.webp';
import ErrorMessage from '../../components/ErrorMessage';
import ErrorSuccessSnackbar from '../../components/ErrorSuccessSnackbar';
import AreaOfInterest from './AreaOfInterest';
import PasswordField from './PasswordField';
import './index.css';

const initSignupInputFields = {
  email: '',
  phone: '',
  password: '',
  confirmPassword: '',
  areaOfInterest: '',
  referer: '',
};
const initInputError = {
  email: '',
  password: '',
  phone: '',
  required: '',
  referer: '',
};
export function isValidEmail(email: string) {
  return /\S+@\S+\.\S+/.test(email);
}
export default function Signup() {
  const navigate = useNavigate();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const refererValue = queryParams.get('referer') || '';
  const [loading, setLoading] = useState(false);
  const [signUpInputFields, setSignUpInputFields] = useState(initSignupInputFields);

  const [inputError, setInputError] = useState(initInputError);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  useEffect(() => setSignUpInputFields((prev) => ({ ...prev, referer: refererValue })), []);

  const onChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent,
  ) => {
    setInputError((prev) => ({ ...prev, phone: '', required: '' }));

    const { name } = event.target;
    const value = event.target.value?.trim();

    if (name === 'email') {
      if (isValidEmail(value)) {
        setInputError((prev) => ({ ...prev, email: '' }));
      } else {
        setInputError((prev) => ({ ...prev, email: 'Please enter a valid email address.' }));
      }
    } else if (name === 'confirmPassword') {
      if (signUpInputFields.password !== value) {
        setInputError((prev) => ({
          ...prev,
          password: 'Password and confirmation Password do not match.',
        }));
      } else {
        setInputError((prev) => ({ ...prev, password: '' }));
      }
    } else if (name === 'password') {
      if (signUpInputFields.confirmPassword !== value) {
        setInputError((prev) => ({ ...prev, password: 'Please make sure your passwords match.' }));
      } else {
        setInputError((prev) => ({ ...prev, password: '' }));
      }
    }
    setSignUpInputFields((prevValue) => ({
      ...prevValue,
      [name]: value,
    }));
  };

  const signupUser = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (
      !signUpInputFields.email ||
      !signUpInputFields.password ||
      !signUpInputFields.confirmPassword ||
      !signUpInputFields.areaOfInterest
    ) {
      setInputError((prev) => ({ ...prev, required: 'Please fill in the required fields.' }));
      return;
    }
    if (signUpInputFields.confirmPassword !== signUpInputFields.password) {
      return;
    }

    setLoading(true);
    axios
      .post(`${process.env.REACT_APP_BACKEND_BASE_URL}/api/auth/signup`, signUpInputFields)
      .then((response) => {
        setTimeout(() => {
          navigate('/login');
        }, 2000);
        setSuccessMessage(response.data.message);
        setErrorMessage('');
        setLoading(false);
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.errors) {
          setErrorMessage(err.response.data.errors[0].message);
        }
        setSuccessMessage('');
        setLoading(false);
      });
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);

  return (
    <Box
      component='span'
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        fontFamily: 'Poppins !important',
        overflow: 'hidden',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          padding: '1rem',
        }}
      >
        <img src={MiniOrangelogo} height='60' alt='miniorange-logo' />
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: { xs: '100%', md: '50%' },
          margin: 'auto',
          px: { xs: '1.5rem', md: '3rem' },
          py: { xs: '2rem' },
        }}
      >
        <Box sx={{ px: { xs: '0.25rem', md: '3rem' } }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant='h4' align='center' sx={{ display: { xs: 'none', md: 'block' } }}>
                <b>Create an Account</b>
              </Typography>
              <Typography variant='h4' align='left' sx={{ display: { xs: 'block', md: 'none' } }}>
                <b>Get started with MDM</b>
              </Typography>
              <br />
              <Typography
                variant='subtitle2'
                align='center'
                sx={{ display: { xs: 'none', md: 'block' } }}
              >
                Get started with a free trial and enjoy world-class service by miniOrange MDM
              </Typography>
              <Typography
                variant='body1'
                align='left'
                sx={{ display: { xs: 'block', md: 'none' } }}
              >
                Securing enterprises and cloud apps without trading performance
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {inputError.required && <ErrorMessage message={inputError.required} />}
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box display='flex' width='100%'>
                <Typography
                  variant='subtitle2'
                  sx={{ '@media (max-width:600px)': { fontSize: '0.75rem' } }}
                >
                  Business Email
                </Typography>
                <Typography color='#FF5656' variant='subtitle2'>
                  &nbsp;*
                </Typography>
              </Box>
              <TextField
                name='email'
                required
                fullWidth
                id='email'
                size='small'
                autoFocus
                onChange={onChange}
              />
              {inputError.email !== '' && (
                <Typography variant='body2' color='#FF5656'>
                  {inputError.email}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography
                variant='subtitle2'
                sx={{ '@media (max-width:600px)': { fontSize: '0.75rem' } }}
              >
                Phone Number
              </Typography>
              <PhoneInput
                country='us'
                onChange={(phone: string) =>
                  setSignUpInputFields((prevValue) => ({
                    ...prevValue,
                    phone,
                  }))
                }
              />
              {inputError.phone !== '' && (
                <Typography variant='body2' color='#FF5656'>
                  {inputError.phone}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              <PasswordField
                label='Password'
                name='password'
                onChange={onChange}
                showPassword={showPassword}
                handleClickShowPassword={handleClickShowPassword}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <PasswordField
                label='Confirm Password'
                name='confirmPassword'
                onChange={onChange}
                showPassword={showConfirmPassword}
                handleClickShowPassword={handleClickShowConfirmPassword}
              />
            </Grid>
            <Grid item xs={12}>
              {inputError.password !== '' && (
                <Typography variant='body2' color='#FF5656'>
                  {inputError.password}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12}>
              <AreaOfInterest onChange={onChange} />
            </Grid>
            <Grid item xs={12}>
              <Typography variant='subtitle2'>
                By proceeding I agree to the Terms of Use and acknowledge that I have read the
                privacy policy&nbsp;
                <Link href='https://www.miniorange.com/usecases/miniOrange_User_Agreement.pdf'>
                  Terms
                </Link>
                &nbsp;and&nbsp;
                <Link href='https://www.miniorange.com/usecases/miniOrange_Privacy_Policy.pdf'>
                  Policies
                </Link>
              </Typography>
            </Grid>
          </Grid>
          {/* <ToastContainer /> */}
          <Button
            fullWidth
            type='submit'
            variant='contained'
            onClick={(e) => signupUser(e)}
            sx={{ mt: 3, mb: 2, backgroundColor: '#2D3748' }}
          >
            {loading ? <CircularProgress size={25} sx={{ color: '#fff' }} /> : 'SIGN UP'}
          </Button>
          <Grid container justifyContent='center'>
            <Typography variant='subtitle2'>
              Already have an account?&nbsp;
              <NavLink to='/login' style={{ textDecoration: 'none', color: '#1976d2' }}>
                Sign in
              </NavLink>
            </Typography>
          </Grid>
        </Box>
      </Box>
      <ErrorSuccessSnackbar
        opened={Boolean(successMessage) || Boolean(errorMessage)}
        onClose={() => {
          setSuccessMessage('');
          setErrorMessage('');
        }}
        errorMessage={errorMessage}
        successMessage={successMessage}
      />
    </Box>
  );
}
