export const CUSTOMER_AUTH_ROUTE = '/api/auth/login';
export const CUSTOMER_AUTH_SIGNUP_ROUTE = '/api/auth/signup';

// android enterprise backend routes
export const ANDROID_ENTERPRISE_SIGNUP_URL_ROUTE = '/api/androidenterprise/signupurl/create';
export const ANDROID_ENTERPRISE_TOKEN_CAPTURE_ROUTE =
  '/api/androidenterprise/captureenterprisetoken';
export const ANDROID_ENTERPRISE_DELETE_ROUTE = '/api/androidenterprise/remove';
export const ANDROID_ENTERPRISE_GET_ROUTE = '/api/androidenterprise/detail';
export const ANDROID_ENTERPRISE_WEBTOKEN_GET_ROUTE = '/api/androidenterprise/webtoken';

// android device backend routes
export const ANDROID_DEVICE_ENROLLMENT_ROUTE = '/api/androidenterprise/device/enrollment';
export const ANDROID_DEVICES_SYNC_ROUTE = '/api/androidenterprise/devices/sync';
export const ANDROID_DEVICE_REMOVE_ROUTE = '/api/androidenterprise/device/remove';
export const ANDROID_DEVICES_LIST_ROUTE = '/api/androidenterprise/devices/list';
export const ANDROID_DEVICE_REENROLLMENT_ROUTE = '/api/androidenterprise/device/reenrollment';
export const ANDROID_DEVICE_DETAILS_ROUTE = '/api/androidenterprise/devicedetails';
export const ANDROID_DEVICE_COMMAND_ISSUE_ROUTE = '/api/androidenterprise/device/command';
export const ANDROID_DEVICE_RESTRICTION_RETRY_MFA = '/api/android/resend-mfa-action';

// android policy backend routes
export const ANDROID_POLICY_CREATE_ROUTE = '/api/androidenterprise/policy/create';
export const ANDROID_POLICY_UPDATE_ROUTE = '/api/androidenterprise/policy/update';
export const ANDROID_POLICY_DELETE_ROUTE = '/api/androidenterprise/policy/delete';
export const ANDROID_POLICY_DETAILS_ROUTE = '/api/androidenterprise/policy/details';
export const ANDROID_POLICY_LIST_ROUTE = '/api/androidenterprise/policy/list';

// android application route
export const ANDROID_APPLICATION_DETAILS_ROUTE = '/api/androidenterprise/application/details';

// android escalation
export const ANDROID_ESCALATION_ROUTE = '/api/androidhelp';

// end user backend routes
export const USER_CREATE_ROUTE = '/api/user/create';
export const USERS_GET_ROUTE = '/api/users';
export const USER_DELETE_ROUTE = '/api/user/delete';
export const USER_SYNC_ROUTE = '/api/user/sync';

// android device group backend routes
export const DEVICE_GROUP_CREATE_ROUTE = '/api/android/devicegroup/create';
export const DEVICE_GROUP_DELETE_ROUTE = '/api/android/devicegroup/delete';
export const DEVICE_GROUP_UPDATE_ROUTE = '/api/android/devicegroup/update';
export const LINK_DEVICE_WITH_DEVICE_GROUP_ROUTE = '/api/android/devicegroup/link';
export const DEVICE_GROUP_LIST_ROUTE = '/api/androiddevicegroups';

// apple routes
export const APPLE_ENROLLMENT_GET_ROUTE = '/api/appleenrollment/detail';
export const APPLE_ENROLLMENT_DOWNLOAD_PLIST_ROUTE = '/api/appleenrollment/downloadplist';
export const APPLE_ENROLLMENT_CREATE_ROUTE = '/api/appleenrollment/create';
export const APPLE_IOS_DEVICE_ENROLLMENT_ROUTE = '/api/appleenrollment/device/enrollment';
export const APPLE_IOS_DEVICE_DEPROVISION_ROUTE = '/api/appleenrollment/device/deprovision';

export const APPLE_DEVICE_GROUP_CREATE_ROUTE = '/api/apple/devicegroup/create';
export const APPLE_DEVICE_GROUP_DELETE_ROUTE = '/api/apple/devicegroup/delete';
export const APPLE_DEVICE_GROUP_LIST_ROUTE = '/api/apple/devicegroup/list';
export const LINK_APPLE_DEVICE_WITH_DEVICE_GROUP_ROUTE = '/api/apple/devicegroup/link';

export const APPLE_DEVICES_LIST_ROUTE = '/api/apple/devices/list';
export const APPLE_DEVICE_ACTIVATE_DEFAULT_VPN_PROFILE =
  '/api/apple/device/activate-default-vpn-profile';
export const APPLE_DEVICE_RESTRICTION_RETRY_MFA = '/api/apple/device/resend-mfa-action';

export const APPLE_CONFIGURATION_PROFILE_CREATE_ROUTE = '/api/apple/configurationprofile/create';
export const APPLE_CONFIGURATION_PROFILE_DELETE_ROUTE = '/api/apple/configurationprofile/delete';
export const APPLE_CONFIGURATION_PROFILE_UPDATE_ROUTE = '/api/apple/configurationprofile/update';
export const APPLE_CONFIGURATION_PROFILE_LIST_ROUTE = '/api/apple/configurationprofile/list';
export const APPLE_CONFIGURATION_PROFILE_ATTACH_TO_DEVICE_GROUP_ROUTE =
  '/api/apple/configurationprofile/attach-to-device-group';
export const APPLE_CONFIGURATION_PROFILE_DETACH_FROM_DEVICE_GROUP_ROUTE =
  '/api/apple/configurationprofile/detach-from-device-group';
export const APPLE_CONFIGURATION_PROFILE_ATTACH_TO_DEVICE_ROUTE =
  '/api/apple/configurationprofile/attach-to-device';

export const APPLE_APPLICATION_GROUP_CREATE_ROUTE = '/api/apple/applicationgroup/create';
export const APPLE_APPLICATION_GROUP_DELETE_ROUTE = '/api/apple/applicationgroup/delete';
export const APPLE_APPLICATION_GROUP_UPDATE_ROUTE = '/api/apple/applicationgroup/update';
export const APPLE_APPLICATION_GROUP_LIST_ROUTE = '/api/apple/applicationgroup/list';

// apple itune api routes
export const APPLE_APPLICATION_SEARCH_LIST_ROUTE = '/api/apple/applications/search';

// install/remove application group into device group
export const APPLE_APPLICATION_GROUP_ATTACH_TO_DEVICE_GROUP_ROUTE =
  '/api/apple/applicationgroup/attach-to-device-group';
export const APPLE_APPLICATION_GROUP_DETACH_FROM_DEVICE_GROUP_ROUTE =
  '/api/apple/applicationgroup/detach-from-device-group';

// device restriction routes
export const DEVICE_RESTRICTION_VALIDATE_CHALLENGE = '/api/validate-device-challenge';
