import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SyncIcon from '@mui/icons-material/Sync';
import {
  Button,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Tooltip,
  Typography,
} from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import googleLogo from '../../assets/images/google-logo.png';
import CustomHeader from '../../components/CustomHeader';
import CustomTable from '../../components/CustomTable';
import { ISelectItem } from '../../components/Enrollments/definitions';
import ErrorSuccessSnackbar from '../../components/ErrorSuccessSnackbar';
import { ANDROID_OS } from '../../constants';
import DeviceApprovalStagesEnum from '../../enums';
import MainLayout from '../../layouts/MainLayout';
import { ANDROID_DEVICE_MSG } from '../../messages';
import {
  getAndroidDevices,
  syncDeviceList,
  updateDeviceGroup,
  updateMessage,
} from '../../redux/slices/androidDevice/androidDeviceSlice';
import { getAndroidEnterprise } from '../../redux/slices/androidEnterprise/androidEnterpriseSlice';
import { getDeviceGroups } from '../../redux/slices/deviceGroup/deviceGroupSlice';
import { AppDispatch, RootState } from '../../redux/store';
import { COLOR_BLACK, COLOR_WHITE } from '../../theme';
import { messageInit } from '../../utils/common-constants';
import { GET } from '../AndroidEnterprise';
import { IDeviceGroup } from '../DeviceGroup';
import DeviceEnrollment from './DeviceEnrollment';
import CommandsMenu from './components/CommandsMenu';
import { IDevice, IDeviceGroupUpdatePayload } from './definitions';

interface IActionButtonForDevice {
  name: string;
  anchorEl: HTMLElement | null;
}

/* eslint-disable  @typescript-eslint/no-explicit-any */
const makeApprovalComponent = (
  row: any,
  selectApprovalGroup: (e: SelectChangeEvent, deviceName: string, rowId: number) => void,
  deviceGroups: IDeviceGroup[],
) => (
  <Tooltip placement='top' title={ANDROID_DEVICE_MSG.DEVICE_APPROVAL_ACTION_DESCRIPTION}>
    <Button
      variant='contained'
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: 0,
      }}
    >
      <Select
        fullWidth
        labelId='group-select-label2'
        id={String(row.id)}
        label='Group'
        value=''
        onChange={(e) => selectApprovalGroup(e, row.name, row.id)}
        displayEmpty
        variant='outlined'
        renderValue={(selected) => {
          if (!selected) {
            return (
              <Typography variant='button' color={COLOR_WHITE}>
                Approve
              </Typography>
            );
          }
          return <Typography variant='button'>Options</Typography>;
        }}
        sx={{
          '& .MuiSelect-select': {
            padding: '6px 16px',
            paddingRight: '32px !important',
          },
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
          },
          '& .MuiSelect-icon': {
            color: COLOR_WHITE,
          },
        }}
      >
        {deviceGroups.map((group) => (
          <MenuItem key={group._id} value={`${group._id} ${group.name}`}>
            {group.name}
          </MenuItem>
        ))}
      </Select>
    </Button>
  </Tooltip>
);
function AndroidDevices() {
  const dispatch = useDispatch<AppDispatch>();
  const enterpriseId = useSelector(
    (state: RootState) => state.androidEnterprise.enterprise.enterpriseId,
  );
  const deviceGroups = useSelector((state: RootState) => state.deviceGroup.deviceGroups);
  const androidDevice = useSelector((state: RootState) => state.androidDevice);

  const [openEnrolmentDialog, setOpenEnrolmentDialog] = useState<boolean>(false);
  const [rows, setRows] = useState<IDevice[]>([]);
  const [openMenuDropdown, setOpenMenuDropdown] = useState<IActionButtonForDevice[]>(
    androidDevice.androidDevices.map((item) => ({ name: item.name, anchorEl: null })),
  );

  useEffect(() => {
    dispatch(getAndroidEnterprise(GET));
    dispatch(getAndroidDevices());
    dispatch(getDeviceGroups());
  }, []);

  useEffect(() => {
    setRows(androidDevice.androidDevices);
  }, [androidDevice.androidDevices]);

  const selectGroup = (e: SelectChangeEvent, deviceName: string, rowId: number) => {
    const selectedGroup = e.target.value.split(' ');

    const updatedRows = rows.map((row) => {
      if (row.id === rowId) {
        return {
          ...row,
          deviceGroupId: selectedGroup[0],
          deviceGroupName: selectedGroup[1],
        };
      }
      return row;
    });

    setRows(updatedRows);

    const payload: IDeviceGroupUpdatePayload = {
      deviceGroupId: selectedGroup[0],
      deviceGroupName: selectedGroup[1],
      deviceName,
      deviceType: ANDROID_OS,
    };

    dispatch(updateDeviceGroup(payload));
  };

  const selectApprovalGroup = (e: SelectChangeEvent, deviceName: string, rowId: number) => {
    const selectedGroup = e.target.value.split(' ');

    const updatedRows = rows.map((row) => {
      if (row.id === rowId) {
        return {
          ...row,
          deviceGroupId: selectedGroup[0],
          deviceGroupName: selectedGroup[1],
        };
      }
      return row;
    });

    setRows(updatedRows);

    const payload: IDeviceGroupUpdatePayload = {
      deviceGroupId: selectedGroup[0],
      deviceGroupName: selectedGroup[1],
      deviceName,
      deviceType: ANDROID_OS,
      deviceApprovalStatus: DeviceApprovalStagesEnum.approved,
    };

    dispatch(updateDeviceGroup(payload));
  };

  const handleEnrolmentDialog = () => {
    setOpenEnrolmentDialog((prev) => !prev);
  };

  const handleIssueCommandMenu = (event: React.MouseEvent<HTMLElement>, deviceName: string) => {
    setOpenMenuDropdown(() => {
      const anchors: IActionButtonForDevice[] = androidDevice.androidDevices.map((item) => ({
        name: item.name,
        anchorEl: null,
      }));
      const index = anchors.findIndex((item) => item.name === deviceName);
      if (index !== -1) {
        anchors[index] = { ...anchors[index], anchorEl: event.currentTarget };
      }
      return anchors;
    });
  };

  const handleMenuDropdownClose = () => {
    setOpenMenuDropdown(() => {
      const anchors: IActionButtonForDevice[] = androidDevice.androidDevices.map((item) => ({
        name: item.name,
        anchorEl: null,
      }));
      return anchors;
    });
  };

  const androidDeviceGroupList: ISelectItem[] = [];
  deviceGroups.map((group) =>
    androidDeviceGroupList.push({
      _id: group._id,
      value1: group.name,
      value2: group.policy,
    }),
  );

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 60 },
    {
      field: 'user',
      headerName: 'User',
      width: 120,
    },
    {
      field: 'brand',
      headerName: 'Brand',
      width: 100,
    },
    {
      field: 'model',
      headerName: 'Model',
      width: 100,
    },
    {
      field: 'managementMode',
      headerName: 'Management',
      width: 120,
    },
    {
      field: 'state',
      headerName: 'State',
      width: 100,
    },
    {
      field: 'serialNumber',
      headerName: 'Serial Number',
      width: 150,
    },
    {
      field: 'deviceGroup',
      headerName: 'Device Group',
      width: 200,
      renderCell: (params) => {
        const status = params.row.deviceApprovalStatus;
        const disabled =
          status === DeviceApprovalStagesEnum.approval_pending ||
          status === DeviceApprovalStagesEnum.mfa_requested ||
          status === DeviceApprovalStagesEnum.mfa_denied ||
          false;
        const rowId = String(params.row.id);
        return (
          <>
            <span />
            {deviceGroups.length > 0 ? (
              <Tooltip
                placement='top'
                title={disabled ? ANDROID_DEVICE_MSG.APPROVAL_PENDING_GROUP_DISABLED : ''}
              >
                <FormControl sx={{ width: '100%' }} disabled={disabled}>
                  <InputLabel id='demo-simple-select-disabled-label'>{rowId}</InputLabel>
                  <Select
                    fullWidth
                    labelId='group-select-label'
                    id={rowId}
                    label='Group'
                    value={`${params.row.deviceGroupId} ${params.row.deviceGroup}`}
                    onChange={(e) => selectGroup(e, params.row.name, params.row.id)}
                  >
                    {deviceGroups.map((group) => (
                      <MenuItem key={group._id} value={`${group._id} ${group.name}`}>
                        <Typography variant='body1' sx={{ fontSize: '14px' }}>
                          {group.name}
                        </Typography>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Tooltip>
            ) : null}
          </>
        );
      },
    },
    {
      field: 'policy',
      headerName: 'Policy',
      width: 150,
    },
    {
      field: 'deviceRestriction',
      headerName: 'Device Approval',
      width: 150,
      renderCell: (params) => {
        const ApprovedComponent = (
          <Typography variant='body1' sx={{ color: COLOR_BLACK }}>
            {DeviceApprovalStagesEnum.approved}
          </Typography>
        );
        const MFARequestedComponent = (
          <Typography variant='body1' sx={{ color: COLOR_BLACK }}>
            {DeviceApprovalStagesEnum.mfa_requested}
          </Typography>
        );
        const MFADeniedComponent = (
          <Typography variant='body1' sx={{ color: COLOR_BLACK }}>
            {DeviceApprovalStagesEnum.mfa_denied}
          </Typography>
        );
        const ApprovalPendingComponent = makeApprovalComponent(
          params.row,
          selectApprovalGroup,
          deviceGroups,
        );
        const approvalActions: Record<string, JSX.Element> = {
          APPROVAL_PENDING: ApprovalPendingComponent,
          MFA_REQUESTED: MFARequestedComponent,
          MFA_DENIED: MFADeniedComponent,
          APPROVED: ApprovedComponent,
          NO_APPROVAL: ApprovedComponent, // Displaying APPROVED for the devices that are initially set not to go through approval process
        };
        return params.row.deviceApprovalStatus
          ? approvalActions[params.row.deviceApprovalStatus]
          : null;
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 120,
      renderCell: (params) => (
        <>
          <IconButton
            onClick={(event: React.MouseEvent<HTMLElement>) =>
              handleIssueCommandMenu(event, params.row.name)
            }
          >
            <MoreVertIcon />
          </IconButton>
          {openMenuDropdown?.find((item) => item.name === params.row.name)?.anchorEl ? (
            <CommandsMenu
              deviceName={params.row.name}
              anchorEl={
                openMenuDropdown?.find((item) => item.name === params.row.name)?.anchorEl || null
              }
              handleMenu={handleMenuDropdownClose}
              policyName={params.row.policy}
              deviceManagementMode={params.row.managementMode}
              deviceApprovalStatus={params.row.deviceApprovalStatus}
            />
          ) : null}
        </>
      ),
    },
  ];

  return (
    <MainLayout>
      <CustomHeader logo={googleLogo} title='Android Devices' />
      <Grid
        container
        direction='row'
        justifyContent='flex-start'
        alignItems='center'
        padding={2}
        spacing={2}
      >
        <Grid item>
          <Button
            variant='contained'
            onClick={() => dispatch(syncDeviceList({ enterpriseId }))}
            sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
          >
            {androidDevice.loading ? <CircularProgress color='inherit' size={20} /> : <SyncIcon />}
            Sync Devices
          </Button>
        </Grid>
        <Grid item>
          <Button variant='contained' onClick={handleEnrolmentDialog}>
            <AddIcon />
            Enroll
          </Button>
        </Grid>
      </Grid>
      <CustomTable rows={rows} columns={columns} />
      <DeviceEnrollment open={openEnrolmentDialog} handleDialog={handleEnrolmentDialog} />
      {(androidDevice.message.error || androidDevice.message.success) && (
        <ErrorSuccessSnackbar
          opened={androidDevice.message.error || androidDevice.message.success}
          onClose={() => dispatch(updateMessage(messageInit))}
          errorMessage={androidDevice.message.errorMessage}
          successMessage={androidDevice.message.successMessage}
        />
      )}
    </MainLayout>
  );
}

export default AndroidDevices;
