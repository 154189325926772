import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { HTTP_STATUS_CODE } from '../../../constants';
import { IAppleProfile, initAppleProfile } from '../../../pages/AppleProfiles/definitions';
import {
  prepareProfileDataForAPI,
  prepareProfileDataForFrontend,
} from '../../../utils/appleMDMUtilities/appleProfileTypesConversion';
import axiosInstance from '../../../utils/axiosInstance';
import { IMessage, messageInit } from '../../../utils/common-constants';
import {
  APPLE_CONFIGURATION_PROFILE_CREATE_ROUTE,
  APPLE_CONFIGURATION_PROFILE_DELETE_ROUTE,
  APPLE_CONFIGURATION_PROFILE_LIST_ROUTE,
  APPLE_CONFIGURATION_PROFILE_UPDATE_ROUTE,
} from '../../../utils/routes-defs';

interface IAppleProfileSliceState {
  appleProfiles: IAppleProfile[];
  loading: boolean;
  message: IMessage;
}

const initialState: IAppleProfileSliceState = {
  appleProfiles: [],
  loading: false,
  message: messageInit,
};
interface IAppleProfileDeletePayload {
  profileId: string;
}

export const getAppleProfiles = createAsyncThunk(
  'appleProfile/getAppleProfiles',
  async (_, thunkAPI) => {
    try {
      const response = await axiosInstance().get(APPLE_CONFIGURATION_PROFILE_LIST_ROUTE);
      /* eslint-disable  @typescript-eslint/no-explicit-any */
      const allProfiles = response.data.appleConfigProfiles.map((profile: any) => {
        const finalProfile = JSON.parse(JSON.stringify({ ...initAppleProfile, ...profile }));
        return prepareProfileDataForFrontend(finalProfile);
      });
      return allProfiles;
      /* eslint-disable  @typescript-eslint/no-explicit-any */
    } catch (err: any) {
      console.error(err);
      if (
        err.response &&
        err.response.data &&
        err.response.status !== HTTP_STATUS_CODE.FORBIDDEN &&
        err.response.data.errors
      ) {
        thunkAPI.dispatch(
          updateMessage({
            ...messageInit,
            error: true,
            errorMessage: err.response.data.errors[0].message,
          }),
        );
      }
      throw err;
    }
  },
);

export const createAppleProfile = createAsyncThunk(
  'appleProfile/createAppleProfile',
  async (payload: IAppleProfile, thunkAPI) => {
    try {
      const response = await axiosInstance().post(APPLE_CONFIGURATION_PROFILE_CREATE_ROUTE, {
        ...prepareProfileDataForAPI(payload),
      });
      thunkAPI.dispatch(getAppleProfiles());
      return response.data;
      /* eslint-disable  @typescript-eslint/no-explicit-any */
    } catch (err: any) {
      if (err.response && err.response.data && err.response.data.errors) {
        thunkAPI.dispatch(
          updateMessage({
            ...messageInit,
            error: true,
            errorMessage: err.response.data.errors[0].message,
          }),
        );
      }
      throw err;
    }
  },
);

export const deleteAppleProfile = createAsyncThunk(
  'appleProfile/deleteAppleProfile',
  async (payload: IAppleProfileDeletePayload, thunkAPI) => {
    try {
      const { profileId } = payload;
      await axiosInstance().delete(
        `${APPLE_CONFIGURATION_PROFILE_DELETE_ROUTE}?profileId=${profileId}`,
      );
      thunkAPI.dispatch(
        updateMessage({
          ...messageInit,
          success: true,
          successMessage: 'Profile deleted successfully',
        }),
      );
      return payload.profileId;
    } catch (err: any) {
      if (err.response && err.response.data && err.response.data.errors) {
        thunkAPI.dispatch(
          updateMessage({
            ...messageInit,
            error: true,
            errorMessage: err.response.data.errors[0].message,
          }),
        );
        return thunkAPI.rejectWithValue(err.response.data.errors[0].message);
      }
      throw err;
    }
  },
);

export const updateAppleProfile = createAsyncThunk(
  'appleProfile/updateAppleProfile',
  async (payload: IAppleProfile, thunkAPI) => {
    try {
      const response = await axiosInstance().put(APPLE_CONFIGURATION_PROFILE_UPDATE_ROUTE, {
        ...prepareProfileDataForAPI(payload),
      });
      thunkAPI.dispatch(getAppleProfiles());
      return response.data;
      /* eslint-disable  @typescript-eslint/no-explicit-any */
    } catch (err: any) {
      if (err.response && err.response.data && err.response.data.errors) {
        thunkAPI.dispatch(
          updateMessage({
            ...messageInit,
            error: true,
            errorMessage: err.response.data.errors[0].message,
          }),
        );
      }
      throw err;
    }
  },
);

const appleProfileSlice = createSlice({
  name: 'appleProfile',
  initialState,
  reducers: {
    updateMessage: (state, action) => {
      state.message = action.payload;
    },
  },
  extraReducers: (builder) => {
    // get profiles
    builder.addCase(getAppleProfiles.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAppleProfiles.fulfilled, (state, action) => {
      const allProfiles = action.payload;
      state.loading = false;
      state.appleProfiles = allProfiles;
    });
    builder.addCase(getAppleProfiles.rejected, (state) => {
      state.loading = false;
    });

    // create profile
    builder.addCase(createAppleProfile.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createAppleProfile.fulfilled, (state, action) => {
      state.loading = false;
      const responseMessage = action.payload.message;
      state.message = {
        ...messageInit,
        success: true,
        successMessage: responseMessage,
      };
    });
    builder.addCase(createAppleProfile.rejected, (state) => {
      state.loading = false;
    });
    // delete profile
    builder.addCase(deleteAppleProfile.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteAppleProfile.rejected, (state, action) => {
      state.loading = false;
      state.message = {
        ...messageInit,
        error: true,
        errorMessage: action.payload as string,
      };
    });
    builder.addCase(deleteAppleProfile.fulfilled, (state, action) => {
      state.loading = false;
      state.appleProfiles = state.appleProfiles.filter((profile) => profile.id !== action.payload);
    });
    // update profile
    builder.addCase(updateAppleProfile.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateAppleProfile.fulfilled, (state, action) => {
      state.loading = false;
      const responseMessage = action.payload.message;
      state.message = {
        ...messageInit,
        success: true,
        successMessage: responseMessage,
      };
    });
    builder.addCase(updateAppleProfile.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const { updateMessage } = appleProfileSlice.actions;
export default appleProfileSlice;
