import React, { useContext } from 'react';
import { Grid, TextField, Typography, Tooltip, IconButton } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { IAndroidPolicy } from '../../../definitions';
import { PolicyContext } from '../../..';
import DeviceProfileType from '../../../constants';
import AndroidMinimumVersionTooltip from '../../AndroidMinimumVersion';
import ProfileType from '../../ProfileType';
import { ANDROID_POLICY_MSG } from '../../../../../messages';

function SupportMessage() {
  const { policy, setPolicy } = useContext(PolicyContext);
  const { shortSupportMessage, longSupportMessage } = policy.supportMessages;

  const maxLength: number = 200;
  const truncateMessage = (message: string): string =>
    message.length > maxLength ? `${message.slice(0, maxLength)}...` : message;

  const handleShortMessageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = truncateMessage(event.target.value);
    setPolicy((prev: IAndroidPolicy) => ({
      ...prev,
      supportMessages: {
        ...prev.supportMessages,
        shortSupportMessage: {
          ...prev.supportMessages.shortSupportMessage,
          localizedMessages: {
            ...prev.supportMessages.shortSupportMessage.localizedMessages,
            en: value,
          },
        },
      },
    }));
  };

  const handleLongMessageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setPolicy((prev: IAndroidPolicy) => ({
      ...prev,
      supportMessages: {
        ...prev.supportMessages,
        longSupportMessage: {
          ...prev.supportMessages.longSupportMessage,
          localizedMessages: {
            ...prev.supportMessages.shortSupportMessage.localizedMessages,
            en: value,
          },
        },
      },
    }));
  };

  return (
    <Grid container spacing={0}>
      <Grid item xs={12}>
        <Typography variant='body1' sx={{ display: 'flex', alignItems: 'center' }}>
          Short Support Message
          <div style={{ marginLeft: '8px', marginTop: '5px' }}>
            <AndroidMinimumVersionTooltip androidVersion='6.0+' />
            <ProfileType
              profileType={`${DeviceProfileType.BYOD}, ${DeviceProfileType.COMPANY_OWNED}`}
            />
          </div>
          <Tooltip title={ANDROID_POLICY_MSG.SHORTSUPPORT_TOOLTIP_MESSAGE} placement='right-start'>
            <IconButton size='small'>
              <InfoIcon fontSize='small' />
            </IconButton>
          </Tooltip>
        </Typography>

        <TextField
          placeholder='Type here...'
          value={shortSupportMessage.localizedMessages.en ?? shortSupportMessage.defaultMessage}
          fullWidth
          size='small'
          margin='dense'
          onChange={handleShortMessageChange}
        />

        <Typography variant='body1' sx={{ marginTop: 3, display: 'flex', alignItems: 'center' }}>
          Long Support Message
          <div style={{ marginLeft: '8px', marginTop: '5px' }}>
            <AndroidMinimumVersionTooltip androidVersion='6.0+' />
            <ProfileType
              profileType={`${DeviceProfileType.BYOD}, ${DeviceProfileType.COMPANY_OWNED}`}
            />
          </div>
          <Tooltip title={ANDROID_POLICY_MSG.LONGSUPPORT_TOOLTIP_MESSAGE} placement='right-start'>
            <IconButton size='small'>
              <InfoIcon fontSize='small' />
            </IconButton>
          </Tooltip>
        </Typography>

        <TextField
          placeholder='Type here...'
          value={longSupportMessage.localizedMessages.en ?? longSupportMessage.defaultMessage}
          fullWidth
          size='small'
          margin='dense'
          multiline
          rows={4}
          onChange={handleLongMessageChange}
        />
      </Grid>
    </Grid>
  );
}

export default SupportMessage;
