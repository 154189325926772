import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Box, Button, Typography } from '@mui/material';

function SuccessMessage() {
  return (
    <Box
      sx={{
        minHeight: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#f5f5f5', // light gray background for the page
      }}
    >
      <Box
        sx={{
          width: '60%',
          backgroundColor: 'white',
          borderRadius: '8px',
          boxShadow: 3,
          textAlign: 'center',
          p: 4,
        }}
      >
        <CheckCircleOutlineIcon sx={{ fontSize: '3rem', color: 'green' }} />
        <Typography variant='h5' sx={{ mt: 1, fontWeight: 'bold' }}>
          Thank you!
        </Typography>
        <Typography variant='h6' sx={{ mt: 2, mb: 4 }}>
          Device validated successfully, contact your admin for further approval.
        </Typography>
        <Button
          variant='contained'
          color='primary'
          sx={{
            textTransform: 'none',
            px: 3,
            py: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center', // Center content inside the button
            width: '50%',
            mx: 'auto', // Center the button itself
            gap: '0.5rem',
          }}
          onClick={() => {
            // Attempt to close the window
            window.close();

            // If the window didn't close (some browsers block it), navigate away
            if (!window.closed) {
              window.location.href = 'about:blank';
            }
          }}
        >
          Close this page
        </Button>
      </Box>
    </Box>
  );
}
// function SuccessMessage() {
//   return (
//     <Box
//       sx={{
//         display: 'flex',
//         justifyContent: 'center', // Center horizontally
//         alignItems: 'flex-start', // Align to the top vertically
//         height: '200vh', // Full viewport height
//         pt: '2rem', // Add some padding at the top to create space
//         // backgroundColor: 'rgba(0, 0, 0, 0.05)', // Optional: light background overlay
//       }}
//     >
//       <Box
//         sx={{
//           display: 'flex',
//           alignItems: 'center',
//           maxWidth: '80%', // Limit width to prevent it from stretching too much
//           color: 'white',
//           backgroundColor: '#66bb6a',
//           borderRadius: '0.23rem',
//           py: '0.5rem',
//           px: '0.25rem',
//           // height: '10%',
//           boxShadow: 3, // Add a shadow for a more prominent effect
//         }}
//       >
//         <CheckCircleOutlineIcon />
//         <Typography sx={{ ml: '0.25rem', textAlign: 'center' }}>
//           Device validated successfully, contact your admin for further approval.
//         </Typography>
//       </Box>
//     </Box>
//   );
// }

// function SuccessMessage() {
//   return (
//     <Box
//       sx={{
//         display: 'flex',
//         alignItems: 'center',
//         width: '100%',
//         color: 'white',
//         backgroundColor: '#66bb6a',
//         borderRadius: '0.23rem',
//         py: '0.5rem',
//         px: '0.25rem',
//       }}
//     >
//       <CheckCircleOutlineIcon />
//       <Typography sx={{ ml: '0.25rem' }}>
//         Device validated successfully, contact your admin for the further approval.
//       </Typography>
//     </Box>
//   );
// }

export default SuccessMessage;
