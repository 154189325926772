import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../../../utils/axiosInstance';
import { IMessage, IUser, messageInit } from '../../../utils/common-constants';
import {
  USERS_GET_ROUTE,
  USER_CREATE_ROUTE,
  USER_DELETE_ROUTE,
  USER_SYNC_ROUTE,
} from '../../../utils/routes-defs';
import { IUserCreatePayload } from '../../../pages/Users';

interface IUsers {
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  users: any[];
  loading: boolean;
  message: IMessage;
}

const initialState: IUsers = {
  users: [],
  loading: false,
  message: messageInit,
};

export interface IUserDeletePayload {
  userId: string;
}

export const getUsers = createAsyncThunk('users/getUsers', async (_, thunkAPI) => {
  try {
    const response = await axiosInstance().get(USERS_GET_ROUTE);
    return response.data;
    /* eslint-disable  @typescript-eslint/no-explicit-any */
  } catch (err: any) {
    if (err.response && err.response.data && err.response.data.errors) {
      return thunkAPI.rejectWithValue(err.response.data.errors[0].message);
    }
    throw err;
  }
});

export const syncUser = createAsyncThunk('users/syncUser', async (_, thunkAPI) => {
  try {
    const response = await axiosInstance().post(USER_SYNC_ROUTE);
    thunkAPI.dispatch(
      updateMessage({
        ...messageInit,
        success: true,
        successMessage: response.data.message,
      }),
    );
    thunkAPI.dispatch(getUsers());
    return response.data;
  } catch (err: any) {
    if (err.response && err.response.data && err.response.data.errors) {
      thunkAPI.dispatch(
        updateMessage({
          ...messageInit,
          error: true,
          errorMessage: err.response.data.errors[0].message,
        }),
      );
    }
    throw err;
  }
});

export const createUser = createAsyncThunk(
  'users/createUser',
  async (payload: IUserCreatePayload, thunkAPI) => {
    try {
      const response = await axiosInstance().post(USER_CREATE_ROUTE, payload);
      thunkAPI.dispatch(
        updateMessage({
          ...messageInit,
          success: true,
          successMessage: response.data.message,
        }),
      );
      thunkAPI.dispatch(getUsers());
      return response.data;
    } catch (err: any) {
      if (err.response && err.response.data && err.response.data.errors) {
        thunkAPI.dispatch(
          updateMessage({
            ...messageInit,
            error: true,
            errorMessage: err.response.data.errors[0].message,
          }),
        );
      }
      throw err;
    }
  },
);

export const deleteUser = createAsyncThunk(
  'users/deleteUser',
  async (payload: IUserDeletePayload, thunkAPI) => {
    try {
      const { userId } = payload;
      await axiosInstance().delete(`${USER_DELETE_ROUTE}?userId=${userId}`);
      thunkAPI.dispatch(
        updateMessage({
          ...messageInit,
          success: true,
          successMessage: 'User deleted successfully',
        }),
      );
      return payload.userId;
    } catch (err: any) {
      if (err.response && err.response.data && err.response.data.errors) {
        thunkAPI.dispatch(
          updateMessage({
            ...messageInit,
            error: true,
            errorMessage: err.response.data.errors[0].message,
          }),
        );
      }
      throw err;
    }
  },
);
const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    updateMessage: (state, action) => {
      state.message = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUsers.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getUsers.fulfilled, (state, action) => {
      const { users } = action.payload;
      if (Array.isArray(users)) {
        const usersReverseOrder = users
          .reverse()
          .map((userItem: IUser, idx: number) => ({ ...userItem, id: idx + 1 }));

        state.loading = false;
        state.users = usersReverseOrder;
      } else {
        state.loading = false;
        state.message = {
          ...messageInit,
          error: true,
          errorMessage: action.payload as string,
        };
      }
    });
    builder.addCase(getUsers.rejected, (state, action) => {
      state.loading = false;
      state.message = {
        ...messageInit,
        error: true,
        errorMessage: action.payload as string,
      };
    });

    builder.addCase(createUser.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createUser.fulfilled, (state, action) => {
      state.loading = false;
      const usersFromDB = action.payload.users;

      usersFromDB.forEach((userFromDB: any, index: number) => {
        const newUser = {
          _id: userFromDB._id,
          email: userFromDB.email,
          userName: userFromDB.userName,
          id: state.users.length + 1 + index,
        };
        state.users.unshift(newUser);
      });
      state.users = state.users.map((user, idx) => ({ ...user, id: idx + 1 }));
    });
    builder.addCase(createUser.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(syncUser.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(syncUser.fulfilled, (state, action) => {
      state.loading = false;
      const usersFromDB = action.payload.users;

      usersFromDB.forEach((userFromDB: any, index: number) => {
        const newUser = {
          _id: userFromDB._id,
          email: userFromDB.email,
          userName: userFromDB.userName,
          id: state.users.length + 1 + index,
        };
        state.users.unshift(newUser);
      });
      state.users = state.users.map((user, idx) => ({ ...user, id: idx + 1 }));
    });
    builder.addCase(syncUser.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(deleteUser.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteUser.fulfilled, (state, action) => {
      state.loading = false;
      state.users = state.users.filter((user) => user._id !== action.payload);
    });
    builder.addCase(deleteUser.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const { updateMessage } = usersSlice.actions;
export default usersSlice;
