import DeviceApprovalStagesEnum from './enums';

export const GLOBAL_MSG = {};

export const ANDROID_ENROLLMENT_MSG = {
  DIALOG_TITLE: 'Enroll Android Device',
  PAGE_DISABLED_AE: 'Please register for Android Enterprise to continue...',
  DELETE_ENTERPRISE:
    'Managed devices are factory reset. Work profile and other work-related data is deleted from devices. Devices are no longer under administrative control.',
  WARNING_ENTERPRISE:
    'If you intend to create the enterprise within the same tenant in the future, it is advisable to retain the associated policies now to avoid having to recreate them from scratch later.',
  DEVICE_RESTRICTION_DESCRIPTION:
    'If unchecked the device can be used immediately after enrollment otherwise admin need to approve the device by assigning an expected policy for this user',
  MFA_FLOW_DESCRIPTION:
    'The device will go through the configured MFA flow before being assigned with the actual policy',
  ADMIN_APPROVAL_FLOW_DESCRIPTION:
    'Admin can approve and assign the actual policy after manual approval',
  PERSONAL_PROFILE_DESCRIPTION:
    'Selecting this option enables the Work profile setup on personal devices',
  CORPORATE_PROFILE_DESCRIPTION: 'Select this option if the device is company-owned',
  ENROLLMENT_EMAIL_OPTION_DESCRIPTION:
    'Enrollment steps will be sent over email to the selected user(Recommended for personal devices)',
  ENROLLMENT_STEPS_DISPLAY_DESCRIPTION:
    'Enrollment steps will be shown here(Recommended for corporate devices)',
  ENROLLMENT_DEVICE_APPROVAL_DESCRIPTION: `As you want this device to be approved by admin before the actual use, it is recommended it is added to default group with default policy that won't show any apps or login options.`,
  MFA_SELECTED_WARNING_FOR_MINIORANGE_ENDUSER: `MFA flow currently works only for the users present in your miniOrange IAM`,
};

export const ANDROID_DEVICE_MSG = {
  DEVICE_APPROVAL_ACTION_DESCRIPTION: 'Select a group from the dropdown to complete the approval',
  APPROVAL_PENDING_GROUP_DISABLED:
    'The approval for this device is pending, you can change a group while approving the device',
};

export const ANDROID_POLICY_MSG = {
  DEFAULT_POLICY_DELETION_NOT_ALLOWED: 'Default policy can not be deleted',

  SHORTSUPPORT_TOOLTIP_MESSAGE:
    'A message displayed to the user in the settings screen wherever functionality has been disabled by the admin.',
  LONGSUPPORT_TOOLTIP_MESSAGE:
    'A message displayed to the user in the device administators settings screen.',
  SHORTSUPPORT_DEFAULT_MESSAGE: 'Blocked by your IT Admin.',
  LONGSUPPORT_DEFAULT_MESSAGE: 'Blocked by your IT Admin.',

  ADMIN_EMAIL_TOOLTIP: 'Email addresses of device administrators for factory reset protection.',

  DISABLE_ACCOUNT_TYPE_TOOLTIP: 'Account types that can not  be managed by the user.',
};

export const APPLE_ENROLLMENT_MSG = {
  PAGE_DISABLED_APNS: 'Please configure the Apple APNS Certificate to continue...',
  FILE_TYPE_INVALID: 'File type is not valid',
};

export const APPLE_DEVICE_MSG = {
  RETRY_MFA_TOOLTIP: `Available only for ${DeviceApprovalStagesEnum.mfa_requested} and ${DeviceApprovalStagesEnum.mfa_denied} stages.`,
};

export const APPLE_PROFILE_MSG = {
  PROFILE_NAME_REQUIRED: 'Profile name is required',
};

export const APPLE_APPLICATION_MSG = {
  FILE_TYPE_INVALID: 'File type is not valid',
};

export const USER_MSG = {
  USERS_INVALID_FILE_FORMAT: 'Please upload a valid Excel file (.xls, .csv or .xlsx).',
  USERS_FILE_FORMAT:
    'Incorrect file format. The file must have "Email" and "Username" as the first two columns.',
  USER_FILE_WORKSHEET: 'Worksheet not found in the uploaded file.',
};
