import DeviceApprovalStagesEnum from '../../enums';

export interface IDevice {
  id: number;
  _id: string;
  type: string;
  deviceGroup: string;
  deviceGroupId: string;
  user: string;
  name: string;
  model: string;
  serialNumber: string;
  deviceApprovalStatus?: string;
  brand: string;
  managementMode: string;
  enrollmentTime: string;
  state: string;
  policy: string;
}

export interface IDeviceGroupUpdatePayload {
  deviceGroupId: string;
  deviceGroupName: string;
  deviceName: string;
  deviceType: 'android' | 'apple';
  deviceApprovalStatus?: string;
}

export interface IDeviceDeletePayload {
  deviceName: string;
  wipingMessage: string;
  wipeFlags: WipeDataFlagsEnum[];
}

export enum WipeDataFlagsEnum {
  PRESERVE_RESET_PROTECTION_DATA = 'preserve_frp_data',
  WIPE_EXTERNAL_STORAGE = 'wipe_ext_strorage',
}

export enum IIssueCommandEnum {
  LOCK = 'lock',
  CLEAR_APP_DATA = 'clear_app_data',
  REBOOT = 'reboot',
  START_LOST_MODE = 'start_lost_mode',
  STOP_LOST_MODE = 'stop_lost_mode',
  RESET_PASSWORD = 'reset_password',
  DEPROVISION = 'deprovision',
}

export enum IResetPasswordFlagEnum {
  REQUIRE_ENTRY = 'require_entry',
  DO_NOT_ASK_CREDENTIALS_ON_BOOT = 'no_credentials_on_boot',
  LOCK_NOW = 'lock_now',
}

export interface IIssueCommandPayload {
  deviceName: string;
  commandDetails: {
    device_command: IIssueCommandEnum;
    clearAppsDataParams?: {
      apps: string[];
    };
    newPassword?: string;
    resetPasswordActions?: IResetPasswordFlagEnum[];
    startLostOptions?: {
      message?: string;
      phoneNumber?: string;
      emailAddress?: string;
      address?: string;
      organization?: string;
    };
    startLostStatus?: {
      status?: string;
    };
    stopLostStatus?: {
      status?: string;
    };
  };
}

export type EnrollmentButtonLabels = {
  [key: string]: string;
};

export const enrollmentButtonLabels: EnrollmentButtonLabels = {
  'send-email': 'Send Enrollment Email',
  'show-details': 'Show Enrollment Details',
};

export interface IEnrollmentDetails {
  qrCode: string;
  enrollmentToken: string;
  steps: string[];
}

export const enrollmentDetailsInit: IEnrollmentDetails = {
  qrCode: '',
  enrollmentToken: '',
  steps: [],
};

export interface DeviceEnrollmentTokenData {
  deviceApprovalStatus?: DeviceApprovalStagesEnum;
  additionalData?: string;
}

export const DeviceEnrollmentNoApproval: DeviceEnrollmentTokenData = {
  deviceApprovalStatus: DeviceApprovalStagesEnum.no_approval,
};
