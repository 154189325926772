import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IAndroidInvitationPayload } from '../../../components/Enrollments/definitions';
import { HTTP_STATUS_CODE, NOT_FOUND } from '../../../constants';
import DeviceApprovalStagesEnum from '../../../enums';
import {
  IDevice,
  IDeviceDeletePayload,
  IDeviceGroupUpdatePayload,
} from '../../../pages/AndroidDevices/definitions';
import axiosInstance from '../../../utils/axiosInstance';
import { IMessage, messageInit } from '../../../utils/common-constants';
import {
  ANDROID_DEVICES_LIST_ROUTE,
  ANDROID_DEVICES_SYNC_ROUTE,
  ANDROID_DEVICE_ENROLLMENT_ROUTE,
  ANDROID_DEVICE_REMOVE_ROUTE,
  ANDROID_DEVICE_RESTRICTION_RETRY_MFA,
  LINK_DEVICE_WITH_DEVICE_GROUP_ROUTE,
} from '../../../utils/routes-defs';

interface IAndroidDeviceSliceState {
  androidDevices: IDevice[];
  loading: boolean;
  message: IMessage;
}

const initialState: IAndroidDeviceSliceState = {
  androidDevices: [],
  loading: false,
  message: messageInit,
};

export const getAndroidDevices = createAsyncThunk(
  'androidDevice/getAndroidDevices',
  async (_, thunkAPI) => {
    try {
      const response = await axiosInstance().get(`${ANDROID_DEVICES_LIST_ROUTE}?page=1&limit=10`);
      /* eslint-disable  @typescript-eslint/no-explicit-any */
      const allAndroidDevices = response.data.devices.map((device: any, idx: number) => ({
        id: idx + 1,
        _id: device._id,
        type: device.type,
        deviceGroup: device.deviceGroupId?.name ?? NOT_FOUND,
        deviceGroupId: device.deviceGroupId?._id ?? NOT_FOUND,
        user: device.userId?.email ?? NOT_FOUND,
        name: device.android.name,
        model: device.android.model,
        serialNumber: device.android.serialNumber,
        deviceApprovalStatus: device.android.enrollmentTokenData
          ? JSON.parse(device.android.enrollmentTokenData).deviceApprovalStatus
          : DeviceApprovalStagesEnum.approval_pending,
        brand: device.android.brand,
        managementMode: device.android.managementMode,
        enrollmentTime: device.android.enrollmentTime,
        state: device.android.appliedState,
        policy: device.android.appliedPolicyName.split('/')[3],
      }));

      return allAndroidDevices;
      /* eslint-disable  @typescript-eslint/no-explicit-any */
    } catch (err: any) {
      if (
        err.response &&
        err.response.data &&
        err.response.status !== HTTP_STATUS_CODE.FORBIDDEN &&
        err.response.data.errors
      ) {
        thunkAPI.dispatch(
          updateMessage({
            ...messageInit,
            error: true,
            errorMessage: err.response.data.errors[0].message,
          }),
        );
      }
      throw err;
    }
  },
);

export const sendEnrollmentInvitaion = createAsyncThunk(
  'androidDevice/sendEnrollmentInvitaion',
  async (payload: IAndroidInvitationPayload, thunkAPI) => {
    try {
      if (payload.sendEmail) {
        thunkAPI.dispatch(
          updateMessage({
            ...messageInit,
            success: true,
            successMessage: 'Enrollment email sent successfully',
          }),
        );
      }
      const response = await axiosInstance().post(ANDROID_DEVICE_ENROLLMENT_ROUTE, payload);
      thunkAPI.dispatch(getAndroidDevices());
      return response.data;
      /* eslint-disable  @typescript-eslint/no-explicit-any */
    } catch (err: any) {
      if (err.response && err.response.data && err.response.data.errors) {
        return thunkAPI.rejectWithValue(err.response.data.errors[0].message);
      }
      throw err;
    }
  },
);

export const syncDeviceList = createAsyncThunk(
  'androidDevice/syncDeviceList',
  async (payload: { enterpriseId: string }, thunkAPI) => {
    try {
      const response = await axiosInstance().post(ANDROID_DEVICES_SYNC_ROUTE, payload);
      thunkAPI.dispatch(getAndroidDevices());
      return response.data;
      /* eslint-disable  @typescript-eslint/no-explicit-any */
    } catch (err: any) {
      if (err.response && err.response.data && err.response.data.errors) {
        thunkAPI.dispatch(
          updateMessage({
            ...messageInit,
            error: true,
            errorMessage: err.response.data.errors[0].message,
          }),
        );
      }
      throw err;
    }
  },
);

export const updateDeviceGroup = createAsyncThunk(
  'androidDevice/updateDeviceGroup',
  async (payload: IDeviceGroupUpdatePayload, thunkAPI) => {
    try {
      const response = await axiosInstance().post(LINK_DEVICE_WITH_DEVICE_GROUP_ROUTE, payload);
      thunkAPI.dispatch(getAndroidDevices());
      return response.data;
      /* eslint-disable  @typescript-eslint/no-explicit-any */
    } catch (err: any) {
      if (err.response && err.response.data && err.response.data.errors) {
        thunkAPI.dispatch(
          updateMessage({
            ...messageInit,
            error: true,
            errorMessage: err.response.data.errors[0].message,
          }),
        );
      }
      throw err;
    }
  },
);

export const deleteDevice = createAsyncThunk(
  'androidDevice/deleteDevice',
  async (payload: IDeviceDeletePayload, thunkAPI) => {
    try {
      const response = await axiosInstance().post(ANDROID_DEVICE_REMOVE_ROUTE, payload);
      thunkAPI.dispatch(getAndroidDevices());
      return response.data;
      /* eslint-disable  @typescript-eslint/no-explicit-any */
    } catch (err: any) {
      if (err.response && err.response.data && err.response.data.errors) {
        thunkAPI.dispatch(
          updateMessage({
            ...messageInit,
            error: true,
            errorMessage: err.response.data.errors[0].message,
          }),
        );
      }
      throw err;
    }
  },
);

export const sendMFA = createAsyncThunk(
  'androidDevice/sendMFA',
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  async (payload: any, thunkAPI) => {
    try {
      const response = await axiosInstance().post(ANDROID_DEVICE_RESTRICTION_RETRY_MFA, payload);
      thunkAPI.dispatch(getAndroidDevices());
      return response.data;
      /* eslint-disable  @typescript-eslint/no-explicit-any */
    } catch (err: any) {
      if (err.response && err.response.data && err.response.data.errors) {
        thunkAPI.dispatch(
          updateMessage({
            ...messageInit,
            error: true,
            errorMessage: err.response.data.errors[0].message,
          }),
        );
      }
      throw err;
    }
  },
);

const androidDeviceSlice = createSlice({
  name: 'androidDevice',
  initialState,
  reducers: {
    updateMessage: (state, action) => {
      state.message = action.payload;
    },
  },
  extraReducers: (builder) => {
    // get android devices
    builder.addCase(getAndroidDevices.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAndroidDevices.fulfilled, (state, action) => {
      const allAndroidDevices = action.payload;
      state.loading = false;
      state.androidDevices = allAndroidDevices;
    });
    builder.addCase(getAndroidDevices.rejected, (state) => {
      state.loading = false;
    });

    // send enrollment invitation
    builder.addCase(sendEnrollmentInvitaion.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(sendEnrollmentInvitaion.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(sendEnrollmentInvitaion.rejected, (state, action) => {
      state.loading = false;
      if (action.payload) {
        state.message = {
          ...messageInit,
          error: true,
          errorMessage: action.payload as string,
        };
      } else {
        state.message = {
          ...messageInit,
          error: true,
          errorMessage: action.payload as string,
        };
      }
    });

    // sync android device list
    builder.addCase(syncDeviceList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(syncDeviceList.fulfilled, (state, action) => {
      state.loading = false;
      const responseMessage = action.payload.message;
      state.message = {
        ...messageInit,
        success: true,
        successMessage: responseMessage,
      };
    });

    builder.addCase(syncDeviceList.rejected, (state) => {
      state.loading = false;
    });

    // update android device group
    builder.addCase(updateDeviceGroup.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateDeviceGroup.fulfilled, (state, action) => {
      state.loading = false;
      const responseMessage = action.payload.message;
      state.message = {
        ...messageInit,
        success: true,
        successMessage: responseMessage,
      };
    });
    builder.addCase(updateDeviceGroup.rejected, (state) => {
      state.loading = false;
    });

    // delete device entry
    builder.addCase(deleteDevice.pending, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteDevice.fulfilled, (state, action) => {
      state.loading = false;
      const responseMessage = action.payload.message;
      state.message = {
        ...messageInit,
        success: true,
        successMessage: responseMessage,
      };
    });
    builder.addCase(deleteDevice.rejected, (state) => {
      state.loading = false;
    });

    // MFA resend
    builder.addCase(sendMFA.pending, (state) => {
      state.loading = false;
    });
    builder.addCase(sendMFA.fulfilled, (state, action) => {
      state.loading = false;
      const responseMessage = action.payload.message;
      state.message = {
        ...messageInit,
        success: true,
        successMessage: responseMessage,
      };
    });
    builder.addCase(sendMFA.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const { updateMessage } = androidDeviceSlice.actions;
export default androidDeviceSlice;
