import { useSelector } from 'react-redux';
import EnrollmentDialog from '../../components/Enrollments/EnrollmentDialog';
import { ISelectItem } from '../../components/Enrollments/definitions';
import { ANDROID_OS } from '../../constants';
import { ANDROID_ENROLLMENT_MSG } from '../../messages';
import { sendEnrollmentInvitaion } from '../../redux/slices/androidDevice/androidDeviceSlice';
import { RootState } from '../../redux/store';

interface IProps {
  open: boolean;
  handleDialog: () => void;
}
function DeviceEnrollment(props: IProps) {
  const { open, handleDialog } = props;

  const groups = useSelector((state: RootState) => state.deviceGroup.deviceGroups);

  const groupList: ISelectItem[] = [];
  groups.map((group) =>
    groupList.push({
      _id: group._id,
      value1: group.name,
      value2: group.policy,
    }),
  );

  return (
    <EnrollmentDialog
      deviceType={ANDROID_OS}
      title={ANDROID_ENROLLMENT_MSG.DIALOG_TITLE}
      open={open}
      handleDialog={handleDialog}
      groupList={groupList}
      sendEnrollmentInvitation={sendEnrollmentInvitaion}
    />
  );
}

export default DeviceEnrollment;
