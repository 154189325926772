import { useSelector } from 'react-redux';
import EnrollmentDialog from '../../components/Enrollments/EnrollmentDialog';
import { ISelectItem } from '../../components/Enrollments/definitions';
import { sendEnrollmentInvitation } from '../../redux/slices/appleDevice/appleDeviceSlice';
import { RootState } from '../../redux/store';
import { APPLE_OS } from '../../constants';

interface IProps {
  open: boolean;
  handleDialog: () => void;
}
function DeviceEnrollment(props: IProps) {
  const { open, handleDialog } = props;

  const appleDeviceGroups = useSelector(
    (state: RootState) => state.appleDeviceGroup.appleDeviceGroups,
  );

  const groupList: ISelectItem[] = [];
  appleDeviceGroups.map((group) =>
    groupList.push({
      _id: group._id,
      value1: group.name,
      value2: group.description,
    }),
  );

  return (
    <EnrollmentDialog
      deviceType={APPLE_OS}
      title='Enroll Apple (iOS / Mac) Device'
      open={open}
      handleDialog={handleDialog}
      groupList={groupList}
      sendEnrollmentInvitation={sendEnrollmentInvitation}
    />
  );
}

export default DeviceEnrollment;
