import { Checkbox, CircularProgress, Grid, Typography } from '@mui/material';
import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { AppGroupContext } from '..';
import CustomAccordion from '../../../components/CustomAccordion';
import FileDownloadButton from '../../../components/FileDownloadButton';
import FileUploadButton, { getExtension } from '../../../components/FileUploadButton';
import { APPLE_APPLICATION_CONST, NULL } from '../../../constants';
import { APPLE_APPLICATION_MSG } from '../../../messages';
import { updateMessage } from '../../../redux/slices/appleAppGroup/appleAppGroupSlice';
import { AppDispatch } from '../../../redux/store';
import { messageInit } from '../../../utils/common-constants';
import { IAppleApp } from '../definitions';

interface IProps {
  app: IAppleApp;
}
export default function AppCard(props: IProps) {
  const { app } = props;
  const dispatch = useDispatch<AppDispatch>();
  const { setAppGroup } = useContext(AppGroupContext);

  const handleAppSelection = (app: IAppleApp, e: React.ChangeEvent<HTMLInputElement>) => {
    const selected = e?.target.checked;
    setAppGroup((prev) => {
      const newApplicationsState = prev.apps.map((obj) => {
        if (obj.id === app.id) {
          return { ...obj, checked: selected };
        }
        return obj;
      });

      return {
        ...prev,
        apps: newApplicationsState,
      };
    });
  };
  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files !== NULL && e.target.files.length > 0) {
      const configurationFile = e.target.files ? e.target.files[0] : null;
      const allowedExtensions = ['plist', 'xml'];

      const extension = getExtension(e.target.files[0].name);
      if (!allowedExtensions.includes(extension)) {
        dispatch(
          updateMessage({
            ...messageInit,
            error: true,
            errorMessage: APPLE_APPLICATION_MSG.FILE_TYPE_INVALID,
          }),
        );
      }

      if (configurationFile) {
        const reader = new FileReader();

        reader.onload = (event) => {
          const fileContent = event.target?.result as string;

          setAppGroup((prev) => {
            const newApplicationsState = prev.apps.map((obj) => {
              if (obj.id === app.id) {
                return { ...obj, configurationFileContent: fileContent };
              }
              return obj;
            });

            return {
              ...prev,
              apps: newApplicationsState,
            };
          });
        };
        reader.readAsText(configurationFile);
      }
    }
  };

  const handleFileDownload = () => {
    const filename = `Managed-App-Config-${app.bundleId}.plist`;
    const blob = new Blob([app.configurationFileContent as string], { type: 'application/xml' });
    const url = window.URL.createObjectURL(blob);

    // Create a link element to download tthe file
    const link = document.createElement('a');
    link.download = filename;
    link.href = url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  };

  return (
    <CustomAccordion
      key={app.bundleId}
      ariaControls={app.bundleId}
      summary={
        <Grid container direction='row' justifyContent='space-between' alignItems='center'>
          <Grid item sm={2}>
            <Checkbox checked={app.checked} onChange={(e) => handleAppSelection(app, e)} />
          </Grid>
          <Grid item sm={3}>
            {app.iconUrl ? (
              <img src={app.iconUrl} alt={app.trackName} height={50} width={50} />
            ) : (
              <CircularProgress size={35} />
            )}
          </Grid>
          <Grid item sm={6}>
            <Typography variant='body1'>
              {app.trackName ? app.trackName : 'App Name Loading...'}
            </Typography>
          </Grid>
        </Grid>
      }
      details={
        <Grid container direction='row' justifyContent='flex-end' spacing={1} marginTop='-2rem'>
          <Grid item>
            <FileUploadButton
              text={APPLE_APPLICATION_CONST.UPLOAD_XML_BUTTON_TEXT}
              handleFileUpload={handleFileUpload}
              size='small'
              variant='contained'
            />
          </Grid>
          <Grid item>
            <FileDownloadButton
              text={APPLE_APPLICATION_CONST.DOWNLOAD_XML_BUTTON_TEXT}
              handleFileDownload={handleFileDownload}
              size='small'
              variant='contained'
            />
          </Grid>
        </Grid>
      }
    />
  );
}
