// DeleteDialogBox.js
import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { COLOR_RED, COLOR_WHITE } from '../../theme';

interface Props {
  open: boolean;
  onClose: () => void;
  onDelete: () => void;
  item: string;
}
function DeleteDialogBox(props: Props) {
  const { open, onClose, onDelete, item } = props;
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Confirm Deletion</DialogTitle>
      <IconButton
        aria-label='close'
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <Typography> Are you sure you want to delete this {item} ?</Typography>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onDelete}
          variant='contained'
          style={{ backgroundColor: COLOR_RED, color: COLOR_WHITE }}
        >
          Yes
        </Button>
        <Button onClick={onClose} variant='contained'>
          No
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DeleteDialogBox;
