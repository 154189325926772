// import FileUploadIcon from '@mui/icons-material/FileUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import DownloadIcon from '@mui/icons-material/Download';
import AddIcon from '@mui/icons-material/Add';
import SyncIcon from '@mui/icons-material/Sync';
import {
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as XLSX from 'xlsx';
import CustomTable from '../../components/CustomTable';
import ErrorSuccessSnackbar from '../../components/ErrorSuccessSnackbar';
import MainLayout from '../../layouts/MainLayout';
import {
  createUser,
  deleteUser,
  getUsers,
  syncUser,
  updateMessage,
} from '../../redux/slices/users/usersSlice';
import { AppDispatch, RootState } from '../../redux/store';
import { IUser, messageInit, userInit } from '../../utils/common-constants';
import CustomHeader from '../../components/CustomHeader';
import DeleteDialogBox from '../../components/DeleteDialogbox';
import { isValidEmail } from '../Signup';
import FileUploadButton from '../../components/FileUploadButton';
import { USER_MSG } from '../../messages';
import { UNDEFINED, USER_CONST } from '../../constants';

interface ISelectedUser {
  user: IUser;
  loading: boolean;
}

export interface IUserCreatePayload {
  users: IUserArrayPayload[];
}

export interface IUserArrayPayload {
  email: string;
  userName: string | undefined; // undefined because excel sheet row can be empty
}

function Users() {
  const dispatch = useDispatch<AppDispatch>();
  const users = useSelector((state: RootState) => state.users);
  const [open, setOpen] = useState<boolean>(false);
  const [deleteOpen, setDeleteOpen] = useState<boolean>(false);
  const [user, setUser] = useState<IUser>(userInit);
  const [selectedUser, setSelectedUser] = useState<ISelectedUser | null>(null);
  const [invalidEmails, setInvalidEmails] = useState<string[]>([]);
  const [invalidEmailDialogOpen, setInvalidEmailDialogOpen] = useState<boolean>(false);
  const [uploadDialogOpen, setUploadDialogOpen] = useState<boolean>(false);

  const onChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    setUser((prev) => ({
      ...prev,
      [name]: value.trim(),
    }));
  };

  const addUser = () => {
    const payload: IUserCreatePayload = {
      users: [
        {
          email: user.email,
          userName: user.userName,
        },
      ],
    };
    if (!isValidEmail(payload.users[0].email)) {
      dispatch(
        updateMessage({
          ...messageInit,
          error: true,
          errorMessage: 'Invalid Email',
        }),
      );
      return;
    }
    if (!user.userName || user.userName === '') {
      dispatch(
        updateMessage({
          ...messageInit,
          error: true,
          errorMessage: 'Username is required',
        }),
      );
      return;
    }

    dispatch(createUser(payload));
    setUser(userInit);
    setOpen(false);
  };

  const handleUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const fileExtension = file.name.split('.').pop()?.toLowerCase() as string;
      const allowedExtensions = ['xls', 'xlsx', 'csv'];
      if (!allowedExtensions.includes(fileExtension)) {
        dispatch(
          updateMessage({
            ...messageInit,
            error: true,
            errorMessage: USER_MSG.USERS_INVALID_FILE_FORMAT,
          }),
        );
        return;
      }

      const reader = new FileReader();
      reader.onload = async (e) => {
        const binaryString = e.target?.result as string;
        const workbook = XLSX.read(binaryString, { type: 'binary' });
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];

        if (!worksheet) {
          dispatch(
            updateMessage({
              ...messageInit,
              error: true,
              errorMessage: USER_MSG.USER_FILE_WORKSHEET,
            }),
          );
          return;
        }

        const rows: (string | number)[][] = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

        // Validate the file format
        if (rows.length > 0) {
          const headerRow = rows[0];
          if (
            headerRow.length !== 2 ||
            headerRow[0] !== USER_CONST.EXCEL_SHEET_EMAIL_HEADER ||
            headerRow[1] !== USER_CONST.EXCEL_SHEET_USERNAME_HEADER
          ) {
            dispatch(
              updateMessage({
                ...messageInit,
                error: true,
                errorMessage: USER_MSG.USERS_FILE_FORMAT,
              }),
            );
            return;
          }
        }

        const usersToCreate: IUserArrayPayload[] = [];
        const invalidEmails: string[] = [];

        rows.forEach((row, rowIndex) => {
          if (rowIndex === 0) return;

          const email = row[0] ? String(row[0]).trim() : '';
          const username = row[1] === UNDEFINED ? UNDEFINED : String(row[1]);
          if (!email) return;

          if (!isValidEmail(email)) {
            invalidEmails.push(`Invalid email at row ${rowIndex + 1}: ${email}`);
          } else {
            usersToCreate.push({
              email,
              userName: username,
            });
          }
        });

        if (usersToCreate.length > 0) {
          const payload: IUserCreatePayload = { users: usersToCreate };
          await dispatch(createUser(payload));
        }

        if (invalidEmails.length > 0) {
          setInvalidEmails(invalidEmails);
          setInvalidEmailDialogOpen(true);
        }
        setUploadDialogOpen(false);
      };
      reader.readAsArrayBuffer(file);
    }
  };

  useEffect(() => {
    dispatch(getUsers()); // get all users
  }, []);

  const handleDialog = () => {
    setOpen((prev) => !prev);
  };

  const handleDeleteDialog = (user: IUser) => {
    setSelectedUser({ user, loading: false });
    setDeleteOpen(true);
  };

  const handleDeleteClose = () => {
    setDeleteOpen(false);
    setSelectedUser(null);
  };

  const handleDeleteUser = async () => {
    if (selectedUser && selectedUser.user._id) {
      setSelectedUser({ user: selectedUser.user, loading: true });
      const deletePayload = {
        userId: selectedUser.user._id,
      };
      await dispatch(deleteUser(deletePayload));
      setDeleteOpen(false);
      setSelectedUser(null);
    }
  };

  const downloadSampleFile = () => {
    const sampleData = [
      ['Email', 'Username'],
      ['sample@example1.com', 'SampleUser1'],
      ['sample@example2.com', 'SampleUser2'],
      ['sample@example3.com', 'SampleUser3'],
    ];
    const worksheet = XLSX.utils.aoa_to_sheet(sampleData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sample');

    XLSX.writeFile(workbook, 'SampleUserUpload.xlsx');
  };

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 60 },
    {
      field: '_id',
      headerName: '_id',
      width: 250,
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 250,
    },
    {
      field: 'userName',
      headerName: 'User Name',
      width: 200,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      renderCell: (params) => (
        <ButtonGroup disableElevation>
          <Tooltip title='Delete'>
            <Button
              variant='outlined'
              size='small'
              color='error'
              onClick={() => handleDeleteDialog(params.row)}
              disabled={selectedUser?.loading && selectedUser?.user._id === params.row._id}
            >
              {selectedUser?.loading && selectedUser?.user._id === params.row._id ? (
                <CircularProgress color='inherit' size={20} />
              ) : (
                <DeleteIcon />
              )}
            </Button>
          </Tooltip>
        </ButtonGroup>
      ),
    },
  ];

  return (
    <MainLayout>
      <CustomHeader title='Users' />
      <Grid
        container
        direction='row'
        justifyContent='flex-start'
        alignItems='center'
        padding={2}
        spacing={2}
      >
        <Grid item>
          <ButtonGroup sx={{ gap: 1 }}>
            <Button variant='contained' onClick={handleDialog}>
              <AddIcon /> {USER_CONST.ADD_USER_BUTTON_TEXT}
            </Button>
            <Button variant='contained' component='label' onClick={() => setUploadDialogOpen(true)}>
              {USER_CONST.BULK_IMPORT_BUTTON_TEXT}
            </Button>
            <Button
              variant='contained'
              onClick={() => dispatch(syncUser())}
              sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
            >
              {users.loading ? <CircularProgress color='inherit' size={20} /> : <SyncIcon />}
              {USER_CONST.SYNC_USERS_BUTTON_TEXT}
            </Button>
          </ButtonGroup>
        </Grid>
      </Grid>
      <CustomTable rows={users.users} columns={columns} />
      <Dialog fullWidth onClose={handleDialog} open={open}>
        <DialogTitle>Add user</DialogTitle>
        <DialogContent dividers>
          <Grid
            container
            direction='row'
            justifyContent='space-between'
            alignItems='center'
            padding={1}
            spacing={1}
          >
            <Grid item sm={3}>
              <Typography>Email</Typography>
            </Grid>
            <Grid item sm={9}>
              <TextField
                required
                fullWidth
                size='small'
                id='email'
                name='email'
                placeholder='Email'
                value={user.email}
                onChange={onChange}
              />
            </Grid>
            <Grid item sm={3}>
              <Typography>User Name</Typography>
            </Grid>
            <Grid item sm={9}>
              <TextField
                required
                fullWidth
                size='small'
                id='userName'
                name='userName'
                placeholder='UserName'
                value={user.userName}
                onChange={onChange}
              />
            </Grid>
          </Grid>
          <div style={{ textAlign: 'center' }}>
            <Button variant='contained' onClick={addUser}>
              Add User
            </Button>
          </div>
        </DialogContent>
      </Dialog>

      <DeleteDialogBox
        open={deleteOpen}
        onClose={handleDeleteClose}
        onDelete={handleDeleteUser}
        item='user'
      />

      {/* Invalid Emails Dialog */}
      <Dialog
        fullWidth
        open={invalidEmailDialogOpen}
        onClose={() => setInvalidEmailDialogOpen(false)}
      >
        <DialogTitle>
          <Typography variant='h6'>{USER_CONST.EXCEL_SHEET_INVALID_EMAIL_DIALOG_BOX}</Typography>
          <IconButton
            aria-label='close'
            onClick={() => setInvalidEmailDialogOpen(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          {invalidEmails.map((email, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Typography key={index} variant='body1'>
              {email}
            </Typography>
          ))}
        </DialogContent>
      </Dialog>

      {/* File Upload Dialog */}
      <Dialog fullWidth onClose={() => setUploadDialogOpen(false)} open={uploadDialogOpen}>
        <DialogTitle sx={{ position: 'relative' }}>
          <Typography>{USER_CONST.BULK_IMPORT_USER_DIALOG_TEXT}</Typography>
          <IconButton
            onClick={() => setUploadDialogOpen(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container alignItems='center'>
            <Grid item>{USER_CONST.BULK_USER_SAMPLE_FILE_DOWNLOAD_DIALOG_TEXT}</Grid>
            <Grid item>
              <IconButton onClick={downloadSampleFile}>
                <DownloadIcon />
              </IconButton>
            </Grid>
          </Grid>
          <Box display='flex' justifyContent='flex-end' mt={2}>
            <FileUploadButton
              text='Upload Users'
              handleFileUpload={handleUpload}
              variant='contained'
              accept='.xlsx,.xls,.csv'
            />
          </Box>
        </DialogContent>
      </Dialog>

      {(users.message.error || users.message.success) && (
        <ErrorSuccessSnackbar
          opened={users.message.error || users.message.success}
          onClose={() => dispatch(updateMessage(messageInit))}
          errorMessage={users.message.errorMessage}
          successMessage={users.message.successMessage}
        />
      )}
    </MainLayout>
  );
}

export default Users;
