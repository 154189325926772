import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { HTTP_STATUS_CODE } from '../../../constants';
import { IDeviceGroup } from '../../../pages/DeviceGroup';
import axiosInstance from '../../../utils/axiosInstance';
import { IMessage, messageInit } from '../../../utils/common-constants';
import {
  DEVICE_GROUP_CREATE_ROUTE,
  DEVICE_GROUP_DELETE_ROUTE,
  DEVICE_GROUP_LIST_ROUTE,
  DEVICE_GROUP_UPDATE_ROUTE,
} from '../../../utils/routes-defs';

interface IDeviceGroupDeletePayload {
  deviceGroupId: string;
}
interface IdeviceGroupSliceState {
  deviceGroups: IDeviceGroup[];
  loading: boolean;
  message: IMessage;
}

const initialState: IdeviceGroupSliceState = {
  deviceGroups: [],
  loading: false,
  message: messageInit,
};

export const getDeviceGroups = createAsyncThunk(
  'deviceGroup/getDeviceGroups',
  async (_, thunkAPI) => {
    try {
      const response = await axiosInstance().get(DEVICE_GROUP_LIST_ROUTE);
      const allDeviceGroups: IDeviceGroup[] = response.data.deviceGroups
        .reverse()
        .map((groupItem: IDeviceGroup, idx: number) => ({
          id: idx + 1,
          _id: groupItem._id,
          name: groupItem.name,
          description: groupItem.description,
          policy: groupItem.androidPolicyId.name,
          policyId: groupItem.androidPolicyId._id,
        }));

      return allDeviceGroups;
      /* eslint-disable  @typescript-eslint/no-explicit-any */
    } catch (err: any) {
      if (
        err.response &&
        err.response.data &&
        err.response.status !== HTTP_STATUS_CODE.FORBIDDEN &&
        err.response.data.errors
      ) {
        thunkAPI.dispatch(
          updateMessage({
            ...messageInit,
            error: true,
            errorMessage: err.response.data.errors[0].message,
          }),
        );
      }
      throw err;
    }
  },
);

export const createDeviceGroup = createAsyncThunk(
  'deviceGroup/createDeviceGroup',
  async (payload: IDeviceGroup, thunkAPI) => {
    try {
      const response = await axiosInstance().post(DEVICE_GROUP_CREATE_ROUTE, payload);
      thunkAPI.dispatch(
        updateMessage({
          ...messageInit,
          success: true,
          successMessage: response.data.message,
        }),
      );
      thunkAPI.dispatch(getDeviceGroups());
      return response.data;
      /* eslint-disable  @typescript-eslint/no-explicit-any */
    } catch (err: any) {
      if (err.response && err.response.data && err.response.data.errors) {
        thunkAPI.dispatch(
          updateMessage({
            ...messageInit,
            error: true,
            errorMessage: err.response.data.errors[0].message,
          }),
        );
      }
      throw err;
    }
  },
);

export const deleteDeviceGroup = createAsyncThunk(
  'deviceGroup/deleteDeviceGroup',
  async (payload: IDeviceGroupDeletePayload, thunkAPI) => {
    try {
      const { deviceGroupId } = payload;
      await axiosInstance().delete(`${DEVICE_GROUP_DELETE_ROUTE}?deviceGroupId=${deviceGroupId}`);
      thunkAPI.dispatch(
        updateMessage({
          ...messageInit,
          success: true,
          successMessage: 'Device group deleted successfully',
        }),
      );
      return deviceGroupId;
    } catch (err: any) {
      if (err.response && err.response.data && err.response.data.errors) {
        thunkAPI.dispatch(
          updateMessage({
            ...messageInit,
            error: true,
            errorMessage: err.response.data.errors[0].message,
          }),
        );
      }
      throw err;
    }
  },
);

export const updateDeviceGroupPolicy = createAsyncThunk(
  'deviceGroup/updateDeviceGroupPolicy',
  async (payload: IDeviceGroup, thunkAPI) => {
    try {
      const response = await axiosInstance().post(DEVICE_GROUP_UPDATE_ROUTE, payload);
      thunkAPI.dispatch(getDeviceGroups());
      return response.data;
      /* eslint-disable  @typescript-eslint/no-explicit-any */
    } catch (err: any) {
      if (err.response && err.response.data && err.response.data.errors) {
        thunkAPI.dispatch(
          updateMessage({
            ...messageInit,
            error: true,
            errorMessage: err.response.data.errors[0].message,
          }),
        );
      }
      throw err;
    }
  },
);

const deviceGroupSlice = createSlice({
  name: 'deviceGroup',
  initialState,
  reducers: {
    updateMessage: (state, action) => {
      state.message = action.payload;
    },
  },
  extraReducers: (builder) => {
    // get policies
    builder.addCase(getDeviceGroups.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getDeviceGroups.fulfilled, (state, action) => {
      const allDeviceGroups = action.payload;
      state.loading = false;
      state.deviceGroups = allDeviceGroups;
    });
    builder.addCase(getDeviceGroups.rejected, (state) => {
      state.loading = false;
    });

    // create device group
    builder.addCase(createDeviceGroup.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createDeviceGroup.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(createDeviceGroup.rejected, (state) => {
      state.loading = false;
    });
    // Delete device group
    builder.addCase(deleteDeviceGroup.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteDeviceGroup.fulfilled, (state, action) => {
      state.loading = false;
      state.deviceGroups = state.deviceGroups.filter((group) => group._id !== action.payload);
    });
    builder.addCase(deleteDeviceGroup.rejected, (state) => {
      state.loading = false;
    });

    // update device group policy
    builder.addCase(updateDeviceGroupPolicy.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateDeviceGroupPolicy.fulfilled, (state, action) => {
      state.loading = false;
      const responseMessage = action.payload.message;
      state.message = {
        ...messageInit,
        success: true,
        successMessage: responseMessage,
      };
    });
    builder.addCase(updateDeviceGroupPolicy.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const { updateMessage } = deviceGroupSlice.actions;
export default deviceGroupSlice;
