import { ThemeProvider, createTheme } from '@mui/material/styles';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './assets/css/App.css';
import PrivateRoute from './components/PrivateRoute';
import AndroidDevices from './pages/AndroidDevices';
import AndroidEnterprise from './pages/AndroidEnterprise';
import AndroidEscalation from './pages/AndroidEscalation';
import AndroidPolicies from './pages/AndroidPolicies';
import AppleAppGroups from './pages/AppleAppGroups';
import AppleDeviceGroup from './pages/AppleDeviceGroups';
import AppleDevices from './pages/AppleDevices';
import AppleEnrollment from './pages/AppleEnrollment';
import AppleProfiles from './pages/AppleProfiles';
import Dashboard from './pages/Dashboard';
import DeviceGroup from './pages/DeviceGroup';
import Login from './pages/Login';
import Signup from './pages/Signup';
import User from './pages/Users';
import { FONT_POPPINS } from './theme';
import DeviceVerification from './pages/DeviceVerification';
import SuccessMessage from './pages/DeviceVerification/Success';

const theme = createTheme({
  typography: {
    fontFamily: FONT_POPPINS,
    button: {
      textTransform: 'none',
    },
  },
});
function App() {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route path='/login' element={<Login />} />
          <Route path='/signup' element={<Signup />} />
          <Route path='/device-verification' element={<DeviceVerification />} />
          <Route path='/device-verification-success' element={<SuccessMessage />} />
          <Route path='/' element={<PrivateRoute element={<Dashboard />} />} />
          <Route path='/users' element={<PrivateRoute element={<User />} />} />
          <Route path='/androiddevicegroups' element={<PrivateRoute element={<DeviceGroup />} />} />
          <Route
            path='/androidenterprise'
            element={<PrivateRoute element={<AndroidEnterprise />} />}
          />
          <Route path='/androiddevices' element={<PrivateRoute element={<AndroidDevices />} />} />
          <Route path='/androidpolicies' element={<PrivateRoute element={<AndroidPolicies />} />} />
          <Route path='/androidhelp' element={<PrivateRoute element={<AndroidEscalation />} />} />
          <Route path='/appleenrollment' element={<PrivateRoute element={<AppleEnrollment />} />} />
          <Route
            path='/appledevicegroups'
            element={<PrivateRoute element={<AppleDeviceGroup />} />}
          />
          <Route path='/appledevices' element={<PrivateRoute element={<AppleDevices />} />} />
          <Route path='/appleprofiles' element={<PrivateRoute element={<AppleProfiles />} />} />
          <Route
            path='/appleapplications'
            element={<PrivateRoute element={<AppleAppGroups />} />}
          />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
