import { EMPTY_STRING } from '../../constants';

export interface IRestrictions {
  uuid: string;
  identifier: string;
  security: ISecurity;
  deviceFunctionality: IDeviceFunctionality;
  advancedSecurity: IAdvancedSecurity;
  browser: IBrowser;
  applications: IApplications;
  network: INetwork;
  icloud: IIcloud;
  privacy: IPrivacy;
  datetime: IDatetime;
  keyboard: IKeyboard;
}
export interface IDeviceFunctionality {
  allow_use_of_camera?: boolean;
  allow_face_time?: boolean;
  allow_screen_capture?: boolean;
  allow_airdrop?: boolean;
  force_airdrop_unmanaged?: boolean;
  allow_imessage?: boolean;
  allow_spotlight_internet?: boolean;
  allow_siri?: boolean;
  allow_siri_when_locked?: boolean;
  allow_siri_to_query_web?: boolean;
  allow_modify_device_name?: boolean;
  allow_airprint?: boolean;
  allow_cred_store_airprint?: boolean;
  allow_beacon_discovery_airprint?: boolean;
  enforce_tls_trusted_cert_airprint?: boolean;
}

export interface ISecurity {
  // allow_open_from_managed_to_unmanaged_combined?: boolean;
  allow_open_from_managed_to_unmanaged?: boolean;
  allow_open_from_unmanaged_to_managed?: boolean;
  require_managed_pasteboard?: boolean;
  force_encrypted_backup?: boolean;
  allow_erase_content_settings?: boolean;
  allow_configure_screentime_restriction?: boolean;
  allow_passbook_when_locked?: boolean;
  allow_biometric_unlock?: boolean;
  allow_biometric_modification?: boolean;
}

export interface IAdvancedSecurity {
  allow_conf_profile_installation: boolean;
  allow_account_modification: boolean;
  allow_untrusted_tls_prompt: boolean;
  allow_usb_restriction_mode: boolean;
  allow_usb_file_drive_sharing: boolean;
  allow_unpaired_external_boot_to_recovery: boolean;

  allow_password_sharing: boolean;
  allow_password_proximity_requests: boolean;
  allow_passcode_modify: boolean;

  force_airplay_incoming_requests_pp: boolean;
  force_airplay_outgoing_requests_pp: boolean;

  allow_apple_watch_pairing: boolean;
  force_watch_wrist_detect: boolean;

  allow_new_device_setup_in_proximity: boolean;
  allow_autofill_password: boolean;
  force_authenticate_for_autofill: boolean;
}
export interface IBrowser {
  allow_safari_browser?: boolean;
  allow_safari_autofill?: boolean;
  allow_safari_javascript?: boolean;
  allow_safari_popups?: boolean;
  allow_safari_accept_cookies?: number;
  allow_safari_fraud_website_warning?: boolean;
}

export interface IApplications {
  allow_appstore: boolean;
  allow_untrusted_app_installation: boolean;
  allow_configurator_itunes_to_update_apps: boolean;
  allow_app_deletion: boolean;
  allow_system_app_deletion: boolean;
  allow_auto_download_apps_on_multiple_devices_with_same_appleid: boolean;

  allow_inapp_purchase: boolean;
  allow_gamecenter: boolean;
  allow_gamecenter_multiplayer: boolean;
  allow_gamecenter_add_friends: boolean;

  allow_modify_notification_setting: boolean;

  allow_itunes: boolean;
  allow_itunes_file_sharing: boolean;

  allow_news_app: boolean;
  allow_podcast_app: boolean;

  allow_music_service: boolean;
  allow_radio_service: boolean;

  allow_ibookstore: boolean;
  allow_ibookstore_erotica: boolean;
}

export interface INetwork {
  allow_sync_while_roaming: boolean;
  allow_modify_app_cellular_data: boolean;
  allow_modify_bluetooth_settings: boolean;
  allow_modify_bluetooth_sharing_settings_in_settings_app: boolean;
  allow_create_vpn_config: boolean;
  force_wifi_on: boolean;
  allow_wifi_connect_to_allowed_networks: boolean;
  allow_modify_hotspot_settings: boolean;
  allow_modify_esim_settings: boolean;
  allow_nfc_comm: boolean;
}

export interface IIcloud {
  allow_device_backup_icloud: boolean;
  allow_managed_app_icloud_sync: boolean;
  allow_icloud_document_sync: boolean;
  allow_shared_stream_icloud_sync: boolean;
  allow_keychain_icloud_sync: boolean;
  allow_icloud_photo_library: boolean;
  allow_ent_book_backup_icloud: boolean;
  allow_ent_book_metadata_icloud_sync: boolean;
  allow_icloud_private_relay: boolean;
}

export interface IPrivacy {
  allow_find_my_friends: boolean;
  allow_modify_find_my_friends_settings: boolean;
  allow_find_my_device: boolean;
  allow_send_diagnostics_apple: boolean;
  allow_modify_diagnostics_apple: boolean;
  force_limit_ad_tracking: boolean;

  allow_lockscreen_control_center: boolean;
  allow_lockscreen_notification_center: boolean;
  allow_lockscreen_today_view: boolean;
  allow_mail_privacy_protection: boolean;
}

export interface IDatetime {
  set_device_time: boolean;
}

export interface IKeyboard {
  allow_dictionary_word_lookup: boolean;
  allow_predictive_keyboard: boolean;
  allow_auto_correct: boolean;
  allow_spellcheck: boolean;
  allow_keyboard_shortcuts: boolean;

  allow_dictation: boolean;
  process_dictation_on_device: boolean;
  process_translation_on_device: boolean;
  allow_keyboard_swipe: boolean;
}

export interface IAppLayerVPN {
  uuid: string;
  identifier: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

export interface IAppleProfile {
  id?: string;
  name: string;
  description: string;
  os: AppleOSType;
  restrictions: IRestrictions;
  applayerVPN?: IAppLayerVPN;
}

export const initAppleProfile: IAppleProfile = {
  name: EMPTY_STRING,
  description: EMPTY_STRING,
  os: 'ios',
  restrictions: {
    uuid: EMPTY_STRING,
    identifier: EMPTY_STRING,
    deviceFunctionality: {
      allow_use_of_camera: true,
      allow_face_time: true,
      allow_screen_capture: true,
      allow_airdrop: true,
      force_airdrop_unmanaged: false,
      allow_imessage: true,
      allow_spotlight_internet: true,
      allow_siri: true,
      allow_siri_when_locked: true,
      allow_siri_to_query_web: true,
      allow_modify_device_name: true,
      allow_airprint: true,
      allow_cred_store_airprint: true,
      allow_beacon_discovery_airprint: true,
      enforce_tls_trusted_cert_airprint: false,
    },
    security: {
      allow_open_from_managed_to_unmanaged: true,
      allow_open_from_unmanaged_to_managed: true,
      require_managed_pasteboard: true,
      force_encrypted_backup: false,
      allow_erase_content_settings: true,
      allow_configure_screentime_restriction: true,
      allow_passbook_when_locked: true,
      allow_biometric_unlock: true,
      allow_biometric_modification: true,
    },
    advancedSecurity: {
      allow_conf_profile_installation: true,
      allow_account_modification: true,
      allow_untrusted_tls_prompt: true,
      allow_usb_restriction_mode: true,
      allow_usb_file_drive_sharing: true,
      allow_unpaired_external_boot_to_recovery: false,

      allow_password_sharing: true,
      allow_password_proximity_requests: true,
      allow_passcode_modify: true,

      force_airplay_incoming_requests_pp: false,
      force_airplay_outgoing_requests_pp: false,

      allow_apple_watch_pairing: true,
      force_watch_wrist_detect: false,

      allow_new_device_setup_in_proximity: true,
      allow_autofill_password: true,
      force_authenticate_for_autofill: false,
    },
    browser: {
      allow_safari_browser: true,
      allow_safari_autofill: true,
      allow_safari_javascript: true,
      allow_safari_popups: true,
      allow_safari_accept_cookies: 2,
      allow_safari_fraud_website_warning: true,
    },
    applications: {
      allow_appstore: true,
      allow_untrusted_app_installation: true,
      allow_configurator_itunes_to_update_apps: true,
      allow_app_deletion: true,
      allow_system_app_deletion: true,
      allow_auto_download_apps_on_multiple_devices_with_same_appleid: true,

      allow_inapp_purchase: true,
      allow_gamecenter: true,
      allow_gamecenter_multiplayer: true,
      allow_gamecenter_add_friends: true,

      allow_modify_notification_setting: true,

      allow_itunes: true,
      allow_itunes_file_sharing: true,

      allow_news_app: true,
      allow_podcast_app: true,

      allow_music_service: true,
      allow_radio_service: true,

      allow_ibookstore: true,
      allow_ibookstore_erotica: true,
    },
    network: {
      allow_sync_while_roaming: true,
      allow_modify_app_cellular_data: true,
      allow_modify_bluetooth_settings: true,
      allow_modify_bluetooth_sharing_settings_in_settings_app: true,

      allow_create_vpn_config: true,
      force_wifi_on: false,
      allow_wifi_connect_to_allowed_networks: false,
      allow_modify_hotspot_settings: true,
      allow_modify_esim_settings: true,
      allow_nfc_comm: true,
    },
    icloud: {
      allow_device_backup_icloud: true,
      allow_managed_app_icloud_sync: true,
      allow_icloud_document_sync: true,
      allow_shared_stream_icloud_sync: true,
      allow_keychain_icloud_sync: true,
      allow_icloud_photo_library: true,
      allow_ent_book_backup_icloud: true,
      allow_ent_book_metadata_icloud_sync: true,
      allow_icloud_private_relay: true,
    },
    privacy: {
      allow_find_my_friends: true,
      allow_modify_find_my_friends_settings: true,
      allow_find_my_device: true,
      allow_send_diagnostics_apple: true,
      allow_modify_diagnostics_apple: true,
      force_limit_ad_tracking: false,

      allow_lockscreen_control_center: true,
      allow_lockscreen_notification_center: true,
      allow_lockscreen_today_view: true,
      allow_mail_privacy_protection: true,
    },
    datetime: {
      set_device_time: false,
    },
    keyboard: {
      allow_dictionary_word_lookup: true,
      allow_predictive_keyboard: true,
      allow_auto_correct: true,
      allow_spellcheck: true,
      allow_keyboard_shortcuts: true,

      allow_dictation: true,
      process_dictation_on_device: false,
      process_translation_on_device: false,
      allow_keyboard_swipe: true,
    },
  },
  applayerVPN: {
    uuid: EMPTY_STRING,
    identifier: EMPTY_STRING,
    jsonPayload: {
      type: '',
      payload: {},
    },
  }, // Add your app layer VPN configuration here
};

export type AppleOSType = 'ios' | 'macos' | 'tvos' | 'watchos';

export interface IAppleProfileProps {
  osType: AppleOSType;
}
