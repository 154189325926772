enum DeviceApprovalStagesEnum {
  no_approval = 'NO_APPROVAL',
  approval_pending = 'APPROVAL_PENDING',
  approved = 'APPROVED',
  mfa_requested = 'MFA_REQUESTED',
  mfa_denied = 'MFA_DENIED',
  initiate_mfa = 'INITIATE_MFA',
}

export default DeviceApprovalStagesEnum;
