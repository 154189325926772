/* eslint-disable react/jsx-props-no-spreading */
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';

export const getExtension = (filename: string): string => String(filename.split('.').pop());

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

interface IProps {
  text: string;
  handleFileUpload: (event: React.ChangeEvent<HTMLInputElement>) => void;
  // All other button props
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  [x: string]: any;
}
function FileUploadButton(props: IProps) {
  const { text, handleFileUpload, ...restProps } = props;
  return (
    <Button component='label' startIcon={<CloudUploadOutlinedIcon />} {...restProps}>
      {text}
      <VisuallyHiddenInput type='file' onChange={handleFileUpload} />
    </Button>
  );
}

export default FileUploadButton;
