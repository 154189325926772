import {
  Menu,
  MenuItem,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import DeviceApprovalStagesEnum from '../../../enums';
import {
  deleteDevice,
  sendMFA,
  updateMessage,
} from '../../../redux/slices/appleDevice/appleDeviceSlice';
import { AppDispatch } from '../../../redux/store';

interface IProps {
  deviceId: string;
  anchorEl: HTMLElement | null;
  handleMenu: () => void;
  deviceApprovalStatus?: DeviceApprovalStagesEnum;
  deviceState: number;
}

function CommandsMenu(props: IProps) {
  const { deviceId, anchorEl, handleMenu, deviceApprovalStatus, deviceState } = props;
  const dispatch = useDispatch<AppDispatch>();
  const [openDialog, setOpenDialog] = useState(false);

  const handleSendMFA = () => {
    const payload = { deviceId };
    dispatch(sendMFA(payload));
  };

  const handleDeviceDeletion = () => {
    setOpenDialog(true);
  };

  const confirmDeletion = () => {
    const payload = { deviceId };
    if (!(deviceState === 1)) {
      dispatch(
        updateMessage({
          error: true,
          errorMessage: 'Device is Inactive',
        }),
      );
      return;
    }
    dispatch(deleteDevice(payload));
    setOpenDialog(false);
    handleMenu();
  };

  const cancelDeletion = () => {
    setOpenDialog(false);
    handleMenu();
  };

  const isMFAEnabled =
    deviceApprovalStatus === DeviceApprovalStagesEnum.mfa_requested ||
    deviceApprovalStatus === DeviceApprovalStagesEnum.mfa_denied;

  return (
    <>
      <Menu
        id='account-menu'
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleMenu}
        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
      >
        <MenuItem disabled={!isMFAEnabled} onClick={handleSendMFA}>
          Retry MFA
        </MenuItem>
        <MenuItem onClick={handleDeviceDeletion} sx={{ color: 'red' }}>
          <Tooltip title='DEVICE_DEPROVISION_INFORMATION' arrow>
            <span>Deprovision</span>
          </Tooltip>
        </MenuItem>
      </Menu>

      <Dialog open={openDialog} onClose={cancelDeletion}>
        <DialogTitle>Confirm Deprovision</DialogTitle>
        <IconButton
          aria-label='close'
          onClick={cancelDeletion}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers sx={{ paddingY: 3 }}>
          Are you sure you want to deprovision this device? This action cannot be undone.
        </DialogContent>
        <DialogActions>
          <Button variant='outlined' onClick={cancelDeletion} color='primary'>
            Cancel
          </Button>
          <Button variant='contained' onClick={confirmDeletion} color='error'>
            Deprovision
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

CommandsMenu.defaultProps = {
  deviceApprovalStatus: null,
};

export default CommandsMenu;
